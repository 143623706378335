import React from "react";
import "../css/dashboard.css";
import { IoBagOutline } from "react-icons/io5";
import { CiDollar } from "react-icons/ci";
import { CgDanger } from "react-icons/cg";
import { MdOutlineTrendingUp } from "react-icons/md";
import SecondSection from "../components/dashboard/SecondSection";
import ThirdSection from "../components/dashboard/ThirdSection";
import FirstSection from "../components/dashboard/FirstSection";

const MainDashboard = () => {
  const data = [
    { name: "AMZN", weight: "15%" },
    { name: "BABA", weight: "12%" },
    { name: "AMD", weight: "10%" },
    { name: "GME", weight: "7%" },
    { name: "NIO", weight: "7%" },
    { name: "CLSK", weight: "5%" },
    { name: "AAPL", weight: "5%" },
  ];

  const data2 = [
    { name: "AAPL", weight: "+15%" },
    { name: "AMZN", weight: "+12%" },
    { name: "GME", weight: "+10%" },
    { name: "SPY", weight: "-7%" },
    { name: "QQQ", weight: "-7%" },
    { name: "QQQ", weight: "-5%" },
  ];

  const data3 = [
    { name: "Avg. Market (+8%)", weight: "+9.6 %" },
    { name: "Poor Market (-30%)", weight: "-36 %" },
    { name: "Strong Market (+30%)", weight: "+36 %" },
    ,
  ];

  const sliderBackground = {
    background: `linear-gradient(to right, #FFBF1A ${10}%, #FF4080 ${80}%)`,
  };

  const sliderBackground2 = {
    background: `linear-gradient(to right, #2FEA9B ${10}%, #7FDD53 ${80}%)`,
  };

  const boxes = [
    {
      icon: IoBagOutline,
      title: "Total Balance",
      subtitle: "$ 131.67",
      bgColor: "#FFDBB2",
    },
    {
      icon: CiDollar,
      title: "Total Profit",
      subtitle: "$ 315.67",
      bgColor: "#C5FFC0",
    },
    {
      icon: CgDanger,
      title: "Estimated Return",
      subtitle: "+31.59%",
      bgColor: "#BCFFE3",
    },
  ];

  const graphTypeData = ["ALL", "2022", "2023", "2024"];

  const portfolioGraphdata1 = [
    {
      symbol: "SPY",
      name: "SPDR S&P 500 ETF Trust",
      wtd: "9.3%",
      mtd: "30%",
      ytd: "303%",
    },
    {
      symbol: "IVV",
      name: "Vanguard S&P 500 ETF",
      wtd: "7%",
      mtd: "28.9%",
      ytd: "289.1%",
    },
    {
      symbol: "VOO",
      name: "Vanguard S&P 500 ETF",
      wtd: "5.1%",
      mtd: "21.1%",
      ytd: "201.15%",
    },
    {
      symbol: "VTI",
      name: "Vanguard Total Stock Market ETF",
      wtd: "3.3%",
      mtd: "17.9%",
      ytd: "167%",
    },
    {
      symbol: "QQQ",
      name: "Invesco QQQ Trust Series I",
      wtd: "2.1%",
      mtd: "11.16%",
      ytd: "150%",
    },
  ];

  const portfolioGraphotherData1 = {
    title: "Top Movers",
    gradient_top: "#96BBFE50",
    gradient_bottom: "#ffffff",
    icon: MdOutlineTrendingUp,
  };

  const tableLabels = ["Symbol", "Name", "WTD", "MTD", "YTD"];

  const chartOptions = {
    elements: {
      line: {
        tension: 0.3, // Adjust tension value for desired curve
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return "$" + value; // Append $ to each value
          },
          stepSize: 500, // Set the step size to 500
        },
        title: {
          display: true,
          text: "Price",
        },
        grid: {
          color: "#E2E2E2",
          borderDash: [4, 4], // Dotted lines
        },
        border: {
          dash: [4, 4], // Set dash pattern (repeat)
        },
        min: 0,
        max: 1500,
      },
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not needed
      },
    },
  };

  return (
    <div className="container">
      <FirstSection
        data={data}
        boxes={boxes}
        tableLabels={tableLabels}
        portfolioGraphdata1={portfolioGraphdata1}
        portfolioGraphotherData1={portfolioGraphotherData1}
        graphTypeData={graphTypeData}
        chartOptions={chartOptions}
      />
      <SecondSection
        data2={data2}
        data3={data3}
        sliderBackground={sliderBackground}
        sliderBackground2={sliderBackground2}
      />
      <ThirdSection />
    </div>
  );
};

export default MainDashboard;
