import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../logo.png";
import { useUserAuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router";
import UserRecordsTable from "../components/admin/UserRecordsTable";
import { Box } from "@mui/material";
import TickersTable from "../components/admin/TickersTable";
import DynamicTable from "../components/admin/Database";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const MainWrapper = styled.div`
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 2rem 1rem;
  border-radius: 2rem;
  box-shadow: 0px 0px 8px -5px #000000;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgWrapper = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  border-radius: 3rem;
  border: none;
  padding: 10px 20px;
  text-align: start;
  outline: none;
  margin: 0.5rem;
  width: 90%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;

  &:hover {
    box-shadow: 0px 0px 8px -5px #000000;
    background: rgb(193, 240, 224);
  }

  &:active {
    box-shadow: 0px 0px 8px -5px #000000;
  }

  &:focus {
    background: rgba(144, 238, 144, 0.577);
  }
`;

const Button = styled.button`
  margin-top: 0.5rem;
  padding: 1rem 2rem;
  background: #1fcea5;
  transition: all 0.3s ease;
  border-radius: 1.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background: #1fcea5b0;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  font-medium: 2rem;
  justify-center;
  margin-bottom: 2rem;
`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function AdminPage() {
  const { user } = useUserAuthContext();
  // const [granted, setGranted] = useState(true);
  // const [success, setSuccess] = useState(true);
  const [granted, setGranted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [typedUsername, setTypedUsername] = useState("");
  const [typedPassword, setTypedPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const username = "Manu";
  const password = "Fk9(3FI#K'7Q+9D</@1T";

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // useEffect(() => {
  //   console.log("Granted", granted);
  //   if (granted) {
  //     getAllUsersList();
  //   }
  // }, [granted]);

  const navigate = useNavigate();

  // username and pass verification
  const verification = async () => {
    if (typedUsername === username && typedPassword === password) {
      setSuccess(true);
      // await new Promise((resolve) => setTimeout(resolve, 800));
      setGranted(true);
      // await new Promise((resolve) => setTimeout(resolve, 800));
      // Redirect to another page (you'll need to use React Router for this)
    } else if (typedUsername === "") {
      setErrorMessage("Please type Username!!");
      setError(true);
    } else if (typedPassword === "") {
      setErrorMessage("Please type Secret key!!");
      setError(true);
    } else if (typedUsername !== username) {
      setErrorMessage("Wrong Username!!");
      setError(true);
      setTypedUsername("");
    } else if (typedPassword !== password) {
      setErrorMessage("Wrong Secret key!!");
      setError(true);
      setTypedPassword("");
    } else {
      setErrorMessage("Something went wrong!");
      setError(true);
      setTypedUsername("");
      setTypedPassword("");
    }
  };

  const clearError = () => {
    setError(false);
  };

  // const downloadUserCSV = () => {
  //   const csvData = Papa.unparse(users);

  //   const blob = new Blob([csvData], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "users.csv";
  //   a.click();

  //   URL.revokeObjectURL(url);
  // };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className="px-6 pt-4">
        {granted && (
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Tickers" {...a11yProps(1)} />
            <Tab label="Database Data" {...a11yProps(2)} />
          </Tabs>
        )}
      </div>
      <div>
        {user?.email === "m.junaid.89630@gmail.com" ||
        user?.email === "manujajayawardanais@gmail.com" ||
        user?.email === "mohad238695@gmail.com" ? (
          <>
            <form onSubmit={(e) => e.preventDefault()} hidden={success}>
              <MainWrapper>
                <ImgWrapper>
                  <img
                    style={{ height: "100px", width: "100px" }}
                    src={Logo}
                    alt="LOGO"
                  />
                </ImgWrapper>
                {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Input
                  type="text"
                  value={typedUsername}
                  onChange={(e) => setTypedUsername(e.target.value)}
                  className="input"
                  placeholder="User Name"
                  onFocus={clearError}
                />
                <Input
                  type="password"
                  value={typedPassword}
                  onChange={(e) => setTypedPassword(e.target.value)}
                  className="input"
                  placeholder="Secret key"
                  onFocus={clearError}
                />
                <Button onClick={verification} type="submit">
                  Proceed
                </Button>
              </MainWrapper>
            </form>
            <CustomTabPanel value={value} index={0}>
              <div>
                {success && !granted && (
                  <div>
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Authorizing ...
                    </span>
                  </div>
                )}
                {granted && <UserRecordsTable />}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div>
                {success && !granted && (
                  <div>
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Authorizing ...
                    </span>
                  </div>
                )}
                {granted && <TickersTable />}
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div>
                {success && !granted && (
                  <div>
                    <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                      Authorizing ...
                    </span>
                  </div>
                )}
                {granted && <DynamicTable />}
              </div>
            </CustomTabPanel>
          </>
        ) : (
          // If user is not "m.junaid.89630@gmail.com" || "manujajayawardanais@gmail.com"
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                color: "#1E6DBF",
                fontSize: "2rem",
                fontWeight: "700",
                backgroundColor: "#CCC",
                borderRadius: "5px",
                padding: "1rem 2rem",
              }}
            >
              Sorry!! You are not allowed to <br /> access this page
            </h3>
            <Button
              style={{ marginTop: "1rem" }}
              onClick={() => navigate("/chat")}
            >
              Back to Home
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default AdminPage;
