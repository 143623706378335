import React from "react";
import { FaEllipsisV, FaPlus } from "react-icons/fa";
import "../../css/dashboard.css";
import PortfolioGraph from "../PortfolioGraph";

const FirstSection = ({
  data,
  boxes,
  portfolioGraphdata1,
  tableLabels,
  portfolioGraphotherData1,
  graphTypeData,
  chartOptions,
}) => {
  return (
    <div className="section_first">
      <div className="boxes_mobile">
          {boxes.map((box, i) => {
            const Icon = box.icon;
            return (
              <div className="box" key={i}>
                <div
                  className="icon_div"
                  style={{ backgroundColor: box.bgColor }}
                >
                  <Icon className="icon" />
                </div>
                <div className="text_div">
                  <label className="title">{box.title}</label>
                  <label className="subtitle">{box.subtitle}</label>
                </div>
              </div>
            );
          })}
        </div>
      <div className="custom-table">
        <table>
          <thead>
            <tr className="tr">
            <th className="table_header">Name</th>
              <th className="table_header">Weight</th>
            </tr>
            <tr className="tr">
              <div className="bar_line" />
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <tr className="tr">
                  <td className="td">{item.name}</td>
                  <td className="td">
                  <span className="td_digit">{item.weight}</span>
                    <span className="dots">
                      <FaEllipsisV color="#969696" size={10} />
                    </span>
                  </td>
                </tr>
                {index !== data.length - 1 && (
                  <tr className="tr">
                    <div className="bar_line" />
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div>
          <button className="btn_primary">
            <FaPlus color="#FFFFFF" size={12} />
            <label>{"Name"}</label>
          </button>
        </div>
      </div>
      <div className="section_first_right">
        <div className="boxes">
          {boxes.map((box, i) => {
            const Icon = box.icon;
            return (
              <div className="box" key={i}>
                <div
                  className="icon_div"
                  style={{ backgroundColor: box.bgColor }}
                >
                  <Icon className="icon" />
                </div>
                <div className="text_div">
                  <label className="title">{box.title}</label>
                  <label className="subtitle">{box.subtitle}</label>
                </div>
              </div>
            );
          })}
        </div>
        <div className="chart_container">
          <PortfolioGraph
            tableData={portfolioGraphdata1}
            tableLabels={tableLabels}
            portfolioGraphotherData={portfolioGraphotherData1}
            isTable={false}
            graphTypeData={graphTypeData}
            chartOptions={chartOptions}
          />
        </div>
        <div className="box_container"></div>
      </div>
    </div>
  );
};

export default FirstSection;
