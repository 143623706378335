import React from "react";
import { useNavigate } from "react-router";

const PriceTable = () => {
  const navigate = useNavigate();
  return (
    <section id="pricing" className="gradient-bg">
      <div className="w-full max-w-7xl mx-auto px-4 md:px-6 py-24">
        {/* title */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
          <h2 className="h2 mb-4 text-primary-color">Pricing</h2>
          <p className="text-xl text-secondary-color">
            Explore Our Competitive Pricing Options
          </p>
        </div>

        {/* Pricing toggle */}

        <div className="max-w-sm mx-auto grid md:gap-10 gap-6 lg:grid-cols-1 items-start md:w-2/3 w-full lg:max-w-none">
          {/* <!-- Pricing tab 3 --> */}
          <div className="card h-full" data-aos="fade-up" data-aos-delay="400">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-gray-600    shadow shadow-slate-950/5">
              <div className="mb-5">
                <div className="text-white font-semibold mb-1">
                  Rise Co-Pilot + Terminal
                </div>
                <div className="inline-flex items-baseline mb-2">
                  <span className="text-white dark:text-slate-500 font-bold text-4xl">
                    Get Started for Free
                  </span>
                </div>
                <div className="text-xl text-white mb-5">
                  Experience the V2 Rise Model and our Investment Research
                  terminal.
                </div>
                <a
                  className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#94B9FE] px-3.5 py-2.5 text-lg font-medium text-[#344559] shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                  // href="https://login.riseai.app"
                  onClick={() => navigate("/login")}
                >
                  Try the Rise Terminal
                </a>
              </div>

              {/* <ul className="text-slate-500  text-lg space-y-3 grow">
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Full Suite with Professional Access</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Real Estate Guidance - Customized advice</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Comprehensive Wealth Management Tools</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Professional Consultation Included </span>
                </li>
              </ul> */}
            </div>
          </div>

          {/* <!-- Pricing tab 1 --> */}

          {/* <div className="dark h-full" data-aos="fade-up" data-aos-delay="200">
            <div className="relative flex flex-col h-full p-6 rounded-2xl bg-gray-600  border-slate-200  shadow shadow-slate-950/5">
              <div className="mb-5">
                <div className="text-white dark:text-slate-500 font-semibold mb-1">
                  Rise Model V1
                </div>
                <div className="inline-flex items-baseline mb-2">
                  <span className="text-white font-bold text-3xl">£</span>
                  <span className="text-white dark:text-slate-500 font-bold text-4xl">
                    0.00
                  </span>
                  <span className="text-white font-medium">/mo</span>
                </div>
                <div className="text-xl text-white mb-5">
                  Free forever on the GPT store
                </div>
                <a
                  className="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#94B9FE] px-3.5 py-2.5 text-lg font-medium text-[#344559] shadow-sm shadow-indigo-950/10 hover:bg-indigo-600 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                  href="https://chat.openai.com/g/g-t4LOM48Bd-rise-ai"
                >
                  Try Rise V1
                </a>
              </div> */}
          {/* <div className="text-[#344559] font-medium mb-3">Includes:</div> */}
          {/* <ul className="text-slate-500  text-lg space-y-3 grow">
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>24/7 Chatbot</span>
                </li>
                <li className="flex items-center">
                  <svg
                    className="w-3 h-3 fill-blue-500 mr-3 shrink-0"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                  </svg>
                  <span>Predefined chunks as necessary</span>
                </li>
              </ul> */}
        </div>
      </div>
    </section>
  );
};

export default PriceTable;
