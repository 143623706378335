import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  Paper,
  Switch,
  Box,
} from "@mui/material";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  deleteDoc,
} from "firebase/firestore";
import { styled } from "@mui/system";
import { urlReturner } from "../ChatForm";

const StyledTableContainer = styled(TableContainer)({
  width: "90vw",
  height: "80vh",
  margin: "auto",
  marginTop: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledTableHeadRow = styled(TableRow)({
  backgroundColor: "#1976d2",
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  color: "#fff",
  padding: "8px 16px",
});

const StyledTableBodyCell = styled(TableCell)({
  padding: "8px 16px",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
});

const ActionButtonsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px",
});

const StyledButton = styled(Button)({
  margin: "8px",
});

const UserRecordsTable = () => {
  const db = getFirestore();
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [firstVisible, setFirstVisible] = useState(null);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const fetchUsers = async (direction, cursor) => {
    let q;
    const usersCollection = collection(db, "Users");

    if (direction === "initial") {
      q = query(usersCollection, orderBy("createdAt", "desc"), limit(10));
    } else if (direction === "next") {
      q = query(
        usersCollection,
        orderBy("createdAt", "desc"),
        startAfter(cursor),
        limit(10)
      );
    } else if (direction === "prev") {
      q = query(
        usersCollection,
        orderBy("createdAt", "desc"),
        endBefore(cursor),
        limit(10)
      );
    }

    const userDocs = await getDocs(q);
    const usersData = userDocs.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setUsers(usersData);
    setLastVisible(userDocs.docs[userDocs.docs.length - 1]);
    setFirstVisible(userDocs.docs[0]);
    setIsNextDisabled(userDocs.docs.length < 10);
    setIsPrevDisabled(direction === "initial" || userDocs.empty);
  };

  useEffect(() => {
    fetchUsers("initial");
  }, []);

  const handleNext = () => {
    if (lastVisible) fetchUsers("next", lastVisible);
  };

  const handlePrev = () => {
    if (firstVisible) fetchUsers("prev", firstVisible);
  };

  const updateUser = async (id, field, value) => {
    const userDoc = doc(db, "Users", id);
    await updateDoc(userDoc, { [field]: value });
    setUsers(
      users.map((user) => (user.id === id ? { ...user, [field]: value } : user))
    );
  };

  const deleteUser = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (isConfirmed) {
      const userDoc = doc(db, "Users", id);
      await deleteDoc(userDoc);
      setUsers(users.filter((user) => user.id !== id));
    }
  };

  const joinedDate = (timestamp) => {
    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };
  return (
    <StyledTableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <StyledTableHeadRow>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell>Permitted</StyledTableCell>
            <StyledTableCell>Admin</StyledTableCell>
            <StyledTableCell>Answered Questionnaire</StyledTableCell>
            <StyledTableCell>Joined At</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </StyledTableHeadRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <StyledTableRow key={user.id}>
              <StyledTableBodyCell>{user.email}</StyledTableBodyCell>
              <StyledTableBodyCell>
                <Switch
                  checked={user.isPermitted}
                  onChange={(e) =>
                    updateUser(user.id, "isPermitted", e.target.checked)
                  }
                />
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <Switch
                  checked={user.isAdmin}
                  onChange={(e) =>
                    updateUser(user.id, "isAdmin", e.target.checked)
                  }
                />
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <Switch
                  checked={user.hasAnsweredQuestionaire}
                  onChange={(e) =>
                    updateUser(
                      user.id,
                      "hasAnsweredQuestionaire",
                      e.target.checked
                    )
                  }
                />
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                {joinedDate(user.createdAt)}
              </StyledTableBodyCell>
              <StyledTableBodyCell>
                <StyledButton
                  color="secondary"
                  onClick={() => deleteUser(user.id)}
                >
                  Delete
                </StyledButton>
              </StyledTableBodyCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
      <ActionButtonsContainer>
        <StyledButton
          onClick={handlePrev}
          disabled={isPrevDisabled}
          variant="contained"
          color="primary"
        >
          Prev
        </StyledButton>
        <StyledButton
          onClick={handleNext}
          disabled={isNextDisabled}
          variant="contained"
          color="primary"
        >
          Next
        </StyledButton>
      </ActionButtonsContainer>
    </StyledTableContainer>
  );
};

export default UserRecordsTable;
