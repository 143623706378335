import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCXFQ05WEhZ6rQ40Bvfqo6xqcR_oQUxCDg",
  authDomain: "rise-ai-finance.firebaseapp.com",
  projectId: "rise-ai-finance",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;