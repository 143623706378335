import React, { useEffect, useState } from "react";
import { useChat } from "../context/ChatProvider";

const useMessages = () => {
  const { socket, currentRoom, rooms } = useChat();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setMessages(currentRoom.messages);
  }, [currentRoom, rooms]);

  return messages;
};

export default useMessages;
