import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import css from "../css/portfolio.module.css";

// Registering chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const PortfolioGraph = ({
  tableData,
  tableLabels,
  portfolioGraphotherData,
  isTable = true,
  graphTypeData,
  chartOptions,
}) => {
  const [selectedData, setSelectedData] = useState(tableData[0]);
  const [graphType, setGraphType] = useState("WTD");

  const handleGraphTypeChange = (type) => {
    setGraphType(type);
  };

  const chartData = {
    labels: [
      "Jan 2024",
      "Feb 2024",
      "Mar 2024",
      "Apr 2024",
      "May 2024",
      "Jun 2024",
      //   "Jul 2023",
      //   "Aug 2023",
      //   "Sep 2023",
      //   "Oct 2023",
      //   "Nov 2023",
      //   "Dec 2023",
    ],
    datasets: [
      {
        label: selectedData.name,
        data: Array(1000)
          .fill()
          .map(() => Math.floor(Math.random() * 1500)), // Random data for illustration
        borderColor: portfolioGraphotherData.gradient_top,
        borderWidth: 1,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, portfolioGraphotherData.gradient_bottom);
          gradient.addColorStop(1, portfolioGraphotherData.gradient_top);

          return gradient;
        },
        pointRadius: 0, //To remove that cirlce form the graph line
        pointHoverRadius: 0,
      },
    ],
  };

  const options = {
    // maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Price",
        },
      },
    },
    plugins: {
      legend: {
        display: false, //To remove the title above the graph
      },
      tooltip: {
        intersect: false, // To show tooltip at any point along the line
      },
    },
  };
  const tableStyles = !isTable ? { width: "93%" } : {};
  const Icon = portfolioGraphotherData.icon;

  const graphTypes =
    graphTypeData?.length > 0 ? graphTypeData : ["DTD", "WTD", "MTD", "YTD"];
  const chartOpt = chartOptions ? chartOptions : options;

  return (
    <div className={css.container}>
      {isTable && (
        <div className={css.table_container}>
          <h3>
            {portfolioGraphotherData.title} &nbsp; &nbsp;{" "}
            <Icon
              size={24}
              style={{ color: portfolioGraphotherData.gradient_top }}
            />{" "}
          </h3>
          <table className={css.data_table}>
            <thead>
              <tr>
                {tableLabels.map((label, index) => (
                  <th key={index} className={css.header_cell}>
                    {label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => setSelectedData(item)}
                  className={css.data_row}
                >
                  <td className={css.data_cell}>{item.symbol}</td>
                  <td className={css.data_cell}>{item.name}</td>
                  <td
                    className={css.data_cell}
                    style={{ color: portfolioGraphotherData.gradient_top }}
                  >
                    {item.wtd}
                  </td>
                  <td
                    className={css.data_cell}
                    style={{ color: portfolioGraphotherData.gradient_top }}
                  >
                    {item.mtd}
                  </td>
                  <td
                    className={css.data_cell}
                    style={{ color: portfolioGraphotherData.gradient_top }}
                  >
                    {item.ytd}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className={css.graph_container} style={tableStyles}>
        <div className={css.button_title_wrapper}>
          {isTable ? (
            <p>{selectedData.name}</p>
          ) : (
            <p>
              Total Return &nbsp;
              <span className={css.green_dot}></span>&nbsp;
              <span className={css.pnl_text}>Pnl</span>
              &nbsp; $350.55
            </p>
          )}
          <div className={css.button_container}>
            {graphTypes?.map((type) => (
              <button
                key={type}
                onClick={() => handleGraphTypeChange(type)}
                className={`${css.graph_button} ${
                  graphType === type ? css.active : ""
                }`}
              >
                {type}
              </button>
            ))}
          </div>
        </div>
        <Line data={chartData} options={chartOpt} width={450} height={200} />
      </div>
    </div>
  );
};

export default PortfolioGraph;
