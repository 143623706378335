// src/pages/AdminFrame.jsx
import React from 'react';

const AdminFrame = () => {
  return (
    <iframe 
      src="/admin/index.html" 
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Admin"
    />
  );
};

export default AdminFrame;
