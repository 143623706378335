import React, { useState } from "react";
import styled from "styled-components";
import RoomList from "./RoomList";
import ChatForm from "./ChatForm";
import Conversation from "./Conversation";
import { useChat } from "../context/ChatProvider";
import { Stack, useMediaQuery } from "@mui/material";
import { ButtonContainer } from "../styled/Button";
import { IoMenu } from "react-icons/io5";

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 1.5vw;
  // flex-direction: column;
  height: 100%;
  // margin: auto 0;
  // margin-top: 25px;
  // padding: 3vw 1vw;

  @media (max-width: 820px) {
    height: 100%;
    margin-top: 0;
    flex-direction: column;

  }
`;

const Chat = styled.div`
  padding: var(--vertical-padding) var(--vertical-padding) 1.5vh
    var(--vertical-padding);
  display: flex;
  flex: 1;
  margin-right: 15px;
  flex-direction: column;
  max-height: 82vh;
  min-height: 82vh;
  background: #fff;
  border-radius: 30px;
  //   box-shadow: 0px 10px 67px 36px rgba(0, 0, 0, 0.4);
  //   background: #082440;

  @media (max-width: 820px) {
    margin: 1rem 2.5vw;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 1.1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 1em;
  height: 3.2em;

  & img {
    height: 100%;
    border-radius: 0.7em;
  }

  & h2 {
    font-size: 0.85em;
    font-weight: 600;
    color: #fff;
  }
`;

const WelcomeMessage = styled.p`
  margin: auto 0;
  font-size: 0.9em;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
`;

const ChatCoPilot = () => {
  const [query, setQuery] = useState("");
  const [isNavOpen, setIsNavOpen] = useState();
  const smUp = useMediaQuery("(max-width : 900px)");
  const { currentRoom } = useChat();

  // console.log(process.env.REACT_APP_CURRENT_ENVIORONEMNT);

  return (
    <>
      <RoomList
        query={query}
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
      />
      <CenterContainer>
        <Chat>
          {!currentRoom ? (
            <WelcomeMessage>
              Come join the fun! <br /> Chat with friends or meet new ones in
              one of our lively chat rooms.
              <br /> See you there! 🙋🏽‍♂️
            </WelcomeMessage>
          ) : (
            <>
              <Header>
                {/* <img alt="room-img" src={TempImage} /> */}

                <div style={{ marginLeft: "10px" }}>
                  {/* <h2 color="#fff">
                    {
                      rooms.filter((room) => room?.id === currentRoom?.id)[0]
                        ?.name
                    }
                  </h2>
                  <Description color="#000" size="0.75em">
                    {currentRoom.description}
                  </Description> */}
                  Rise AI Co-Pilot
                </div>
                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  {currentRoom !== null && (
                    <>
                      {smUp && (
                        <ButtonContainer
                          device="mobile"
                          onClick={() => setIsNavOpen(true)}
                          active={true}
                          style={{ marginLeft: 10 }}
                        >
                          <a
                            href="#"
                            style={{
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            }}
                          >
                            <IoMenu size="80%" color="black" />
                          </a>
                        </ButtonContainer>
                      )}
                    </>
                  )}
                </Stack>
              </Header>

              <Conversation />

              <ChatForm />
            </>
          )}
        </Chat>
      </CenterContainer>
    </>
  );
};

export default ChatCoPilot;
