import React from "react";
import styled from "styled-components";
import { useChat } from "../context/ChatProvider";
import useChatActions from "../hooks/useChatActions";
import useDebounce from "../hooks/useDebounce";
import { Description } from "../styled/Description";
import { Button, Stack, useMediaQuery } from "@mui/material";
import { useUserAuthContext } from "../context/AuthProvider";
import AlertDialog from "./LaodingDialog";
import { MdModeEdit } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { ButtonContainer } from "../styled/Button";

const RoomListContainer = styled.div`
  --space: 1em;
  --horizontal-space: 2vw;

  display: flex;
  flex-direction: column;
  width: 18%;
  min-width: 180px;
  height: 100vh;
  padding-top: var(--vertical-padding);
  overflow: auto;
  border-radius: 25px;
  // box-shadow: 0px 10px 67px 36px rgba(0, 0, 0, 0.4);
  background: #ffffff;
  color: #000000;
  max-height: 85vh;
  // margin-top: 30px;
  & h3 {
    font-size: 1.2em;
    font-weight: 500;
    padding: 0.9em var(--horizontal-space);
  }

  @media (max-width: 820px) {
    position: absolute;
    opacity: ${(props) => (props.open ? "1" : "0")};
    pointer-events: ${(props) => (props.open ? "null" : "none")};
    right: 0;
    width: 100%;
    border-radius: 0;
    z-index: 1;
  }
`;

const RoomItem = styled.li`
  display: flex;
  gap: 1vw;
  width: 95%;
  flex: 1;
  justify-content: space-between;
  // padding: var(--space) var(--horizontal-space);
  padding: 10px 20px;
  margin: 8px auto;
  list-style: none;
  background: ${(props) =>
    props.active
      ? "#d5d5d5"
      : "#F4F3F3"}; /* Change background color for room items or use this var(--blue-active-color) */
  cursor: pointer;
  transition: all 0.05s;
  border-radius: 10px;

  &:hover {
    // background: var(--blue-active-color);
    background: #d5d5d5;
  }

  & img {
    height: 3vw;
    width: 3vw;
    border-radius: 20px;
    object-fit: cover;
  }

  & div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  & span {
    font-weight: 500;
    font-size: 0.8em;
  }
`;

const RoomList = ({ query, isNavOpen, setIsNavOpen }) => {
  const debouncedSearch = useDebounce(query, 350);
  const smUp = useMediaQuery("(max-width : 900px)");
  const { joinRoom } = useChatActions();
  const {
    currentRoom,
    setCurrentRoom,
    userName,
    rooms,
    getChatRooms,
    loading,
  } = useChat();
  const { addRoom } = useUserAuthContext();

  const handleRoomClick = (roomID) => {
    const selectedRoom = rooms.find((room) => room.id === roomID);
    setCurrentRoom(selectedRoom);

    joinRoom({ roomID, userName });

    setIsNavOpen(false);
  };

  return (
    <RoomListContainer open={isNavOpen}>
      {rooms.length <= 20 && (
        <Stack
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          sx={{ mb: 2, width: "90%", mx: "auto" }}
        >
          <Button
            variant="contained"
            onClick={async () => {
              const id = await addRoom(`Chat Room - ${rooms.length + 1}`);
              await getChatRooms(id);
            }}
            style={{
              backgroundColor: "#96BBFE",
              borderRadius: "10px",
              textTransform: "capitalize",
              fontSize: "16px",
              width: "100%",
            }} // Apply specific styles to the button
          >
            + New Chat
          </Button>
          {currentRoom !== null && (
            <>
              {true && (
                <ButtonContainer
                  device="mobile"
                  onClick={() => setIsNavOpen(false)}
                  active={true}
                  style={{ marginLeft: 10 }}
                >
                  <a
                    href="#"
                    style={{
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <IoCloseOutline size="100%" color="black" />
                  </a>
                </ButtonContainer>
              )}
            </>
          )}
        </Stack>
      )}
      <ul>
        {rooms.map((room) => {
          const { id, name, messages } = room;

          return (
            <RoomItem
              active={currentRoom?.id === id}
              key={id}
              onClick={() => handleRoomClick(id)}
            >
              {/* <img alt="room-img" src={TempImage} /> */}
              {/* <StyledLogo rtl={false}>Rise</StyledLogo> */}
              <div>
                <span>{name}</span>
                <Description color="#000000a8" size="0.7em">
                  {messages[0]?.text}
                </Description>
              </div>
              <div>
                {" "}
                <MdModeEdit />
              </div>
            </RoomItem>
          );
        })}
      </ul>

      {loading && <AlertDialog content={"Loading User Data"} />}
    </RoomListContainer>
  );
};

export default RoomList;
