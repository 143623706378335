// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-8 px-4 sm:px-6">
      <h1 className="text-3xl font-semibold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        Welcome to Rise AI, your AI Investment/Wealth Advisor. Rise AI ("we",
        "us", "our") is designed to be your investing expert, accessible anytime
        and anywhere. It is developed to provide you with informational and
        educational resources on investing, utilizing advanced artificial
        intelligence trained on over 1300 financial data sources. 
        
        This tool uses AI, so it should not be considered financial advice - 
        it is purely informational and educational. Now, ask me anything!
        
        Our website,
        accessible at{" "}
        <a href="https://riseai.app/" className="text-blue-500">
          https://riseai.app/
        </a>
        , serves as a platform for engaging with Rise AI (the "Service”), which
        can also directly be accessed on the OpenAI GPT store at{" "}
        <a
          href="https://chat.openai.com/g/g-t4LOM48Bd-rise-ai"
          className="text-blue-500"
        >
          https://chat.openai.com/g/g-t4LOM48Bd-rise-ai
        </a>
        .
      </p>

      <h2 className="text-xl font-semibold mb-2">Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing and using our Service, you acknowledge and agree to the
        terms outlined in this Privacy Policy and any additional terms and
        conditions that are referenced herein or that are presented to you at
        the time of use of our Service. If you do not agree to these terms, you
        should not use our Service.
      </p>

      <h2 className="text-xl font-semibold mb-2">Use of the Service</h2>
      <p className="mb-4">
        Rise AI is built on OpenAI's foundational model and incorporates
        artificial intelligence to process and respond to user queries. While we
        strive to provide valuable insights, it is crucial to understand that
        our responses are AI-generated and should not be construed as financial
        advice. The accuracy, completeness, or reliability of the information
        provided cannot be guaranteed. We strongly advise consulting with a
        professional financial advisor before making any investment decisions.
        By using Rise AI, you agree that the Service is provided for educational
        purposes only, and we shall not be liable for any decisions, actions, or
        outcomes resulting from your use of our Service.
      </p>

      <h2 className="text-xl font-semibold mb-2">Data Collection and Use</h2>
      <p className="mb-4">
        In providing our Service, we may use external APIs, such as the Yahoo
        Finance API for accessing stock, ETF, and other security-specific data,
        and DuckDuckGo search for web browsing capabilities, in addition to
        OpenAI's native web browsing features. Our use of these APIs and any
        serverless functions and GPT Actions is designed to enhance the Service
        by retrieving relevant financial data to better respond to user queries.
        We reserve the right to transmit any part of your conversation with Rise
        AI to our external server for the sole purpose of data retrieval and
        improving our Service. However, we commit to not storing any personal
        information, user questions, or conversations on our side.
      </p>

      <h2 className="text-xl font-semibold mb-2">Legal Disclaimer</h2>
      <p className="mb-4">
        Our Service is provided on an "as is" and "as available" basis. We
        expressly disclaim all warranties of any kind, whether express or
        implied, including, but not limited to, the implied warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
        We make no warranty that the Service will meet your requirements, be
        safe, secure, uninterrupted, timely, accurate, or error-free. You agree
        that your use of the Service is at your sole risk and that you will be
        solely responsible for any damage or loss that results from such use.
      </p>

      <h2 className="text-xl font-semibold mb-2">
        Changes to This Privacy Policy
      </h2>
      <p className="mb-4">
        We reserve the right to update or change our Privacy Policy at any time.
        We will notify you of any changes by posting the new Privacy Policy on
        this page. You are advised to review this Privacy Policy periodically
        for any changes. Changes to this Privacy Policy are effective when they
        are posted on this page.
      </p>

      <h2 className="text-xl font-semibold mb-2">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a href="mailto:contact@riseai.app" className="text-blue-500">
          contact@riseai.app
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
