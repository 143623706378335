import React from "react";
import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import FeaturesZigZag from "../partials/FeaturesZigzag";
import Footer from "../partials/Footer";
import PriceTable from "../partials/PriceTable";
import Partners from "../partials/Partners";

function Home() {

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/* Site header */}
      <Header />

      {/* Page content */}
      <main className="grow">
        {/* Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/* Page sections */}
        <HeroHome />

        {/* <FeaturesBlocks /> */}
        <FeaturesZigZag />
        <Partners />
        
        <PriceTable />
        {/* <Newsletter /> */}
        {/* <Testimonials /> */}
      </main>

      {/* Site footer */}
      <Footer />
    </div>
  );
}

export default Home;
