import React from "react";
import analytics from "../images/analytics.svg";
import assets from "../images/assets.svg";
import invest from "../images/invest.svg";
import portfolio from "../images/portfolio.svg";
import robo from "../images/robo.svg";
import risk from "../images/risk.svg";

function FeaturesBlocks() {
  return (
    <section id="features" className="bg-[#CDFFD8]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-primary-color">
              The majority our customers do not understand their workflows.
            </h2>
            <p className="text-xl text-secondary-color">
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </p>
          </div>

          {/* Items */}
          <div
            className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:gap-16 items-start md:max-w-2xl lg:max-w-none"
            data-aos-id-blocks
          >
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={robo} alt="robo" />
              <h4 className="h4 mb-2 text-primary-color">Robo-Advisory</h4>
              <p className="text-lg text-secondary-color text-center">
                AI algorithms can offer automated investment advice and
                portfolio management based on a user's financial goals and risk
                tolerance.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={assets} alt="assets" />
              <h4 className="h4 mb-2 text-primary-color">Expense Tracking</h4>
              <p className="text-lg text-secondary-color text-center">
                Users can track and categorize their expenses, view spending
                trends, and set budgeting goals with the help of AI-powered
                tools.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={invest} alt="invest" />
              <h4 className="h4 mb-2 text-primary-color">
                Investment Recommendations
              </h4>
              <p className="text-lg text-secondary-color text-center">
                AI can suggest suitable investment options, such as stocks,
                bonds, or cryptocurrencies, based on the user's financial
                situation and goals.
              </p>
            </div>

            {/* 4th item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={risk} alt="risk" />
              <h4 className="h4 mb-2 text-primary-color">Risk Assessment</h4>
              <p className="text-lg text-secondary-color text-center">
                Rise. AI can assess a user's risk tolerance and provide guidance
                on appropriate investment strategies and asset allocation.
              </p>
            </div>

            {/* 5th item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={portfolio} alt="portfolio" />
              <h4 className="h4 mb-2 text-primary-color">Portfolio Analysis</h4>
              <p className="text-lg text-secondary-color text-center">
                Users can get detailed analyses of their investment portfolios,
                including performance metrics and recommendations for
                adjustments.
              </p>
            </div>

            {/* 6th item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-anchor="[data-aos-id-blocks]"
            >
              <img className="p-4" src={analytics} alt="analytics" />
              <h4 className="h4 mb-2 text-primary-color">
                Predictive Analytics
              </h4>
              <p className="text-lg text-secondary-color text-center">
                Rise. AI can offer predictions about future financial trends and
                provide users with insights to make informed decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
