import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  menuClasses,
  sidebarClasses,
} from "react-pro-sidebar";
import styled from "styled-components";
import { useUserAuthContext } from "../context/AuthProvider";
import { GiExitDoor } from "react-icons/gi";
import { useNavigate } from "react-router";
import { useMediaQuery, IconButton } from "@mui/material";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { BiSolidDashboard } from "react-icons/bi";
import { IoBagRemoveOutline } from "react-icons/io5";
import logo from "../resources/logo.webp";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
      height: "100%",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        color: "#0098e5",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#FFFFFF",
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#082440",
      icon: "#000",
      hover: {
        color: "#59d0ff",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: relative; /* Ensure the container is positioned relative */

  > div {
    width: 100%;
    overflow: hidden;
  }

  &::after {
    content: "";
    display: block;
    width: 80%; /* Adjust this value to control the length of the line */
    height: 1px; /* Adjust this value to control the thickness of the line */
    background-color: #e0e0e0; /* Adjust this value to change the color of the line */
    position: absolute;
    bottom: 0; /* Adjust this value to control the distance from the bottom of the container */
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Img = styled.img`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  border-radius: 2px;
  background-color: #009fdb;
  margin-right: 10px;
`;

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const SidebarMenu = ({
  showScreen,
  setShowCurrentScreen,
  setToggled,
  toggled,
}) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [rtl, setRtl] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 900px)");
  const [hasImage, setHasImage] = React.useState(false);
  const [theme, setTheme] = React.useState("dark");
  const { user, logout } = useUserAuthContext();

  const leaveClickHandler = () => {
    logout();
    navigate(`/`);
  };

  const toggleSidebar = () => {
    setToggled(!toggled);
  };

  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              hasImage && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        color: themes[theme].menu.hover.color,
        [`& .${menuClasses.icon}`]: {
          color: themes[theme].menu.hover.color,
        },
      },
    },
    activeButton: {
      color: themes[theme].menu.hover.color,
      [`& .${menuClasses.icon}`]: {
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        height: !isMobile && "100vh",
        direction: rtl ? "rtl" : "ltr",
        top: 0,
        bottom: 0,
      }}
    >
      {isMobile && (
        <IconButton
          onClick={toggleSidebar}
          style={{ position: "absolute", top: 10, left: 10, zIndex: 1100 }}
        ></IconButton>
      )}
      {(!isMobile || toggled) && (
        <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          onBackdropClick={() => setToggled(false)}
          onBreakPoint={setBroken}
          image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
          rtl={rtl}
          breakPoint="md"
          backgroundColor={hexToRgba(
            themes[theme].sidebar.backgroundColor,
            hasImage ? 0.9 : 1
          )}
          rootStyles={{
            color: themes[theme].sidebar.color,
            [`.${sidebarClasses.container}`]: {
              height: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              bottom: 0,
              width: "250px",
              zIndex: 1000,
            },
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <StyledSidebarHeader>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Img src={logo} alt="LOGO" />
                <span
                  style={{ fontSize: "18px", fontWeight: "700", color: "#000" }}
                >
                  Rise AI
                </span>
              </div>
            </StyledSidebarHeader>
            <div style={{ flex: 1, marginBottom: "32px" }}>
              <div style={{ padding: "0 24px", marginBottom: "8px" }}></div>
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  onClick={() => setShowCurrentScreen("Rise CoPilot")}
                  icon={<IoChatbubbleEllipsesOutline size={20} />}
                  rootStyles={
                    showScreen === "Rise CoPilot"
                      ? menuItemStyles.activeButton
                      : {}
                  }
                >
                  Rise CoPilot
                </MenuItem>
                <MenuItem
                  onClick={() => setShowCurrentScreen("Dashboard")}
                  icon={<BiSolidDashboard size={20} />}
                  rootStyles={
                    showScreen === "Dashboard"
                      ? menuItemStyles.activeButton
                      : {}
                  }
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => setShowCurrentScreen("Portfolio")}
                  icon={<IoBagRemoveOutline size={20} />}
                  rootStyles={
                    showScreen === "Portfolio"
                      ? menuItemStyles.activeButton
                      : {}
                  }
                >
                  Portfolio
                </MenuItem>
                <MenuItem
                  onClick={leaveClickHandler}
                  icon={<GiExitDoor size={20} />}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Sidebar>
      )}
    </div>
  );
};

export default SidebarMenu;
