import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import { urlReturner } from "../ChatForm";

const DynamicTable = () => {
  const [data, setData] = useState([]);
  const [tableName, setTableName] = useState("");
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableNames, setTableNames] = useState([
    "income_statement",
    "balance_sheet",
    "cash_flow",
    "pg_stat_statements_info",
    "earnings",
    "pg_stat_statements",
    "company_overview",
    "corporate_actions",
    "news",
    "stocks",
    "tickers",
  ]);

  const fetchData = () => {
    setLoading(true);
    fetch(`${urlReturner()}api/data/${tableName}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        if (data.length > 0) {
          let cols = Object.keys(data[0]);
          if (cols.includes("symbol")) {
            cols = ["symbol", ...cols.filter((col) => col !== "symbol")];
          }
          setColumns(cols);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setLoading(false);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleTableChange = (event) => {
    setTableName(event.target.value);
    setCurrentPage(1);
    setColumns([]);
    setData([]);
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = data.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        <Select
          value={tableName}
          onChange={handleTableChange}
          displayEmpty
          variant="outlined"
          style={{ marginBottom: "20px", marginRight: "20px", minWidth: 200 }}
        >
          <MenuItem value="" disabled>
            Select Table
          </MenuItem>
          {tableNames.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
        <Button variant="contained" color="primary" onClick={fetchData}>
          Fetch Data
        </Button>
      </Box>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell key={column}>{row[column]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          />
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TextField
              label="Rows per page"
              type="number"
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              inputProps={{ min: 1 }}
            />
          </div>
        </TableContainer>
      )}
    </div>
  );
};

export default DynamicTable;
