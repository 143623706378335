import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

export default function AlertDialog({content}) {
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            height: "160px",
            width: "300px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop : '10px'
          }}
        >
          <CircularProgress />
          <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
            {content}
          </DialogTitle>
        </div>
      </Dialog>
    </div>
  );
}
