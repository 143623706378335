import React, { useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { ChatProvider } from "./context/ChatProvider";
import { useUserAuthContext } from "./context/AuthProvider";
import AOS from "aos";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import "aos/dist/aos.css";
import "./components/landing/css/style.css";
import "./App.css";
import AdminPage from "./pages/AdminPage";
import usePreventZoom from "./hooks/usePreventZoom";
import { SearchProvider } from "./SearchProvider/SearchProvider";
import Dashboard from "./pages/Dashboard";
import SignupPage from "./pages/SignupPage";
import ProtectedRoute from "./ProtectedRoute";
import Home from "./components/landing/pages/Home";
import HeroHome from "./components/landing/partials/HeroHome";
import FeaturesBlocks from "./components/landing/partials/FeaturesBlocks";
import Testimonials from "./components/landing/partials/Testimonials";
import FeaturesZigzag from "./components/landing/partials/FeaturesZigzag";
import PrivacyPolicy from "./components/landing/pages/PrivacyPolicy";
import BlogFrame from "./components/landing/pages/BlogFrame";
import AdminFrame from "./components/landing/pages/AdminFrame";
import PostList from "./components/posts/PostList";
import Post from "./components/posts/Post";

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color-dark-palette: #1a1a1a;
    --secondry-color-dark-palette: #373737;
    --blue-button-color: #3c95f4;
    --blue-active-color: #2070c6;
    --blue-gradient: linear-gradient(90deg, #3c95f4 65%, #3385dc 100%);
}

  body {
    // background: var(--blue-gradient);
    background: #edf5ef;
  }
`;

// const Background = styled.div`
//   position: absolute;
//   height: 100vh;
//   width: 100vw;
//   overflow: hidden;
//   box-shadow: 0px 10px 67px 36px rgba(0, 0, 0, 0.4);
//   // background: #082440;
//   background: #edf5ef;
//   z-index: -1;

//   &::before,
//   &::after {
//     content: "";
//     position: absolute;
//     inset: -170px auto auto -200px;
//     width: clamp(30vw, 600px, 42vw);
//     height: clamp(30vw, 600px, 42vw);
//     box-shadow: 0px 10px 67px 36px rgba(0, 0, 0, 0.4);
//     border-radius: 50%;
//     // background: #082440;
//     background: #edf5ef;
//     z-index: -1;
//   }

//   &::after {
//     inset: auto -170px -200px auto;
//   }

//   @media (max-width: 820px) {
//     &::before,
//     &::after {
//       width: 25rem;
//       height: 25rem;
//     }
//   }
// `;

function App() {
  const { user } = useUserAuthContext();
  usePreventZoom();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
  }, []);

  // useEffect(() => {
  //   if (process.env.REACT_APP_CURRENT_ENVIORONEMNT === "STAGING") {
  //     const publicRoutes = [
  //       "/",
  //       "/sign-up",
  //       "/forgot-password",
  //       "/verification",
  //     ];
  //     const currentPath = location.pathname.toLowerCase();
  //     // Check if the current path is a public route
  //     const isPublicRoute = publicRoutes
  //       .map((route) => route.toLowerCase())
  //       .includes(currentPath);

  //     if (user !== null && !user.isPermitted && !user.isAdmin) {
  //       // navigate("questions");
  //       navigate("waiting");
  //     } else if (user !== null && !user.hasAnsweredQuestionaire) {
  //       navigate("/");
  //       // Check if the user is not permitted and not an admin
  //     } else if (
  //       user !== null &&
  //       (user.isPermitted || user?.isAdmin) &&
  //       location.pathname !== "/admin"
  //     ) {
  //       navigate("/portfolio");
  //       // navigate("chat");
  //     }

  //     if (process.env.REACT_APP_CURRENT_ENVIORONEMNT === "STAGING") {
  //
  //       if (isPublicRoute) {
  //         // Do nothing, allow access
  //         return;
  //       } else if (user?.canAccessStage) {
  //         navigate("/portfolio");
  //       } else {
  //         navigate("/");
  //       }
  //     }
  //   } else {
  //     // if (user !== null && !user.isPermitted && !user.isAdmin) {
  //     //   // navigate("questions");
  //     //   navigate("waiting");
  //     // } else if (user !== null && !user.hasAnsweredQuestionaire) {
  //     //   navigate("/");
  //     //   // Check if the user is not permitted and not an admin
  //     // } else if (
  //     //   user !== null &&
  //     //   (user.isPermitted || user?.isAdmin) &&
  //     //   location.pathname !== "/admin"
  //     // ) {
  //     //   navigate("/portfolio");
  //     //   // navigate("chat");
  //     // }
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (process.env.REACT_APP_CURRENT_ENVIORONEMNT === "STAGING") {
  //
  //     const publicRoutes = ["/", "/sign-up", "/admin"];
  //     const currentPath = location.pathname.toLowerCase();
  //     // Check if the current path is a public route
  //     const isPublicRoute = publicRoutes
  //       .map((route) => route.toLowerCase())
  //       .includes(currentPath);
  //     if (isPublicRoute) {
  //       // Do nothing, allow access
  //       return;
  //     } else if (user?.canAccessStage) {
  //       navigate("portfolio");
  //     } else {
  //       navigate("/");
  //     }
  //   } else {
  //
  //     if (user !== null && !user.isPermitted && !user.isAdmin) {
  //       navigate("waiting");
  //     } else if (
  //       user !== null &&
  //       (user.isPermitted || user?.isAdmin) &&
  //       location.pathname !== "/admin"
  //     ) {
  //       navigate("portfolio");
  //     } else {
  //       navigate("/");
  //     }
  //   }
  // }, [user]);

  return (
    <>
      <GlobalStyle />
      {/* <Background /> */}
      <ChatProvider>
        <SearchProvider>
          {/* <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPass />} />
            <Route path="/sign-up" element={<SignupPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/verification" element={<VerifyEmail />} />

            {user && !user?.isPermitted && !user.isAdmin && (
              <Route path="/waiting" element={<WaitingPage />} />
            )}
            {user && user?.hasAnsweredQuestionaire && (
              <Route path="/questions" element={<QuestionsPage />} />
            )}
            {user && (user?.isPermitted || user?.isAdmin) && (
              <Route path="/portfolio" element={<Dashboard />} />
            )}
          </Routes> */}

          <Routes>
            <Route path="/sign-up" element={<SignupPage />} />
            <Route
              path="/login"
              element={user ? <Dashboard /> : <LoginPage />}
            />
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<HeroHome />} />
            <Route exact path="/features" element={<FeaturesBlocks />} />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/how_it_works" element={<FeaturesZigzag />} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            {/* <Route exact path="/blog/*" element={<BlogFrame />} />{" "} */}
            <Route exact path="/blog" element={<PostList />} />
            <Route exact path="/blog/:id" element={<Post />} />
            <Route
              exact
              path="/admin/data"
              element={
                <ProtectedRoute
                  user={user}
                  element={<AdminPage />}
                  adminOnly={true}
                />
              }
            />
            <Route exact path="/admin" element={<AdminFrame />} />{" "}
            {/* Route for Gatsby blog */}
            {/* Catch-all route for any undefined paths */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </SearchProvider>
        {/* <Wrapper /> */}
      </ChatProvider>
    </>
  );
}

export default App;
