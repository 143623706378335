import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  Paper,
  Switch,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { urlReturner } from "../ChatForm";
import useFirebaseAuth from "../../hooks/useFirebaseAuth";

const StyledTableContainer = styled(TableContainer)({
  width: "90vw",
  height: "80vh",
  margin: "auto",
  marginTop: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const StyledTableHeadRow = styled(TableRow)({
  backgroundColor: "#1976d2",
});

const StyledTableCell = styled(TableCell)({
  fontWeight: "bold",
  color: "#fff",
  padding: "8px 16px",
});

const StyledTableBodyCell = styled(TableCell)({
  padding: "8px 16px",
});

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f5f5f5",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
});

const ActionButtonsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px",
});

const StyledButton = styled(Button)({
  margin: "8px",
});

const ITEMS_PER_PAGE = 10;

const TickersTable = () => {
  const [tickers, setTickers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredTickers, setFilteredTickers] = useState([]);
  const { user, token } = useFirebaseAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      fetch(`${urlReturner()}admin/tickers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTickers(data);
          setFilteredTickers(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [token]);

  const handleToggleAllowed = async (symbol, allowed) => {
    try {
      const response = await fetch(`${urlReturner()}admin/tickers/${symbol}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ allowed }),
      });

      if (response.ok) {
        setTickers(
          tickers.map((ticker) =>
            ticker.symbol === symbol ? { ...ticker, allowed } : ticker
          )
        );
      } else {
        console.error("Failed to update ticker");
      }
    } catch (error) {
      console.error("Error updating ticker:", error);
    }
  };

  useEffect(() => {
    if (tickers) {
      const filtered = tickers?.filter((ticker) =>
        ticker.symbol.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredTickers(filtered);
      setCurrentPage(1); // Reset to first page when search term changes
    }
  }, [searchTerm, tickers]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNext = () => {
    if (currentPage < Math.ceil(filteredTickers.length / ITEMS_PER_PAGE)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedTickers = filteredTickers.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  );

  return (
    <div>
      <TextField
        label="Search by Symbol"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <StyledTableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <StyledTableHeadRow>
                <StyledTableCell>Symbol</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Exchange</StyledTableCell>
                <StyledTableCell>Asset Type</StyledTableCell>
                <StyledTableCell>IPO Date</StyledTableCell>
                <StyledTableCell>Allowed</StyledTableCell>
              </StyledTableHeadRow>
            </TableHead>
            <TableBody>
              {paginatedTickers.map((ticker) => (
                <StyledTableRow key={ticker.symbol}>
                  <StyledTableBodyCell>{ticker.symbol}</StyledTableBodyCell>
                  <StyledTableBodyCell>{ticker.name}</StyledTableBodyCell>
                  <StyledTableBodyCell>{ticker.exchange}</StyledTableBodyCell>
                  <StyledTableBodyCell>{ticker.assetType}</StyledTableBodyCell>
                  <StyledTableBodyCell>{ticker.ipoDate}</StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <Switch
                      checked={ticker.allowed}
                      onChange={(e) =>
                        handleToggleAllowed(ticker.symbol, e.target.checked)
                      }
                      // disabled
                    />
                  </StyledTableBodyCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
          <ActionButtonsContainer>
            <StyledButton
              onClick={handlePrev}
              disabled={currentPage === 1}
              variant="contained"
              color="primary"
            >
              Prev
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={
                currentPage === Math.ceil(filteredTickers.length / ITEMS_PER_PAGE)
              }
              variant="contained"
              color="primary"
            >
              Next
            </StyledButton>
          </ActionButtonsContainer>
        </StyledTableContainer>
      )}
    </div>
  );
};

export default TickersTable;
