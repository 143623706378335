import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
  getDocs,
  collection,
  query,
  serverTimestamp,
  where,
  orderBy,
} from "firebase/firestore";

import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

const UserAuthContext = createContext();

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export const UserAuthContextProvider = ({ children }) => {
  var db = getFirestore();

  //data in localhost to avoid signup/in on the refresh of page
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [currentURL, setCurrentURL] = useState({});
  const [testingUrl, setTesingUrl] = useState("");
  const [urlsData, setUrlsData] = useState([]);
  const [timeActive, setTimeActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState(null);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (user) {
      initialize();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  useEffect(() => {
    getUrlsData();
  }, []);

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const res = await fetch("https://api.ipify.org");
        const data = await res.text();
        setIp(data);
      } catch (error) {
        console.log("🚀 ~ fetchIp ~ error:", error);
      }
    };
    fetchIp();
  }, []);

  useEffect(() => {
    const fetchLocation = async () => {
      if (!ip) return;

      try {
        const res = await fetch(
          `https://ipinfo.io/${ip}/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`
        );
        const data = await res.json();
        setLocation(data);
      } catch (error) {
        console.log("Error fetching location:", error);
      }
    };

    fetchLocation();
  }, [ip]);

  const initialize = async () => {
    const userDocRef = doc(db, "Users", user.id);
    const userDocSnapshot = await getDoc(userDocRef);
    if (
      !deepEqual(
        JSON.parse(localStorage.getItem("user")),
        userDocSnapshot.data()
      )
    ) {
      setUser({
        ...userDocSnapshot.data(),
      });
    }
  };

  const googleSignin = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider)
      .then(async (result) => {
        setLoading(true);
        var user = result.user;

        const userDocRef = doc(db, "Users", user?.uid);
        const chatRoomDocRef = doc(db, "ChatRoom", user?.uid);
        const newsLetterDocRef = doc(db, "NewsCollection", user?.email);

        // Getting the userDocon the bases if ID
        const userDocSnapshot = await getDoc(userDocRef); //  By userDocSnapshot.data() we can access our stored values

        if (process.env.REACT_APP_CURRENT_ENVIORONEMNT === "STAGING") {
          if (!userDocSnapshot.exists()) {
            setLoading(false);
            alert("Not Allowed");
          } else if (
            localStorage.getItem("user") !== null ||
            (localStorage.getItem("user") === undefined &&
              !deepEqual(
                JSON.parse(localStorage.getItem("user")),
                userDocSnapshot.data()
              ))
          ) {
            localStorage.removeItem("user");
            setUser({
              ...userDocSnapshot.data(),
            });
            setLoading(false);
          } else {
            setUser({
              ...userDocSnapshot.data(),
            });
            setLoading(false);
          }
        }

        if (!userDocSnapshot.exists()) {
          const userDataToStore = {
            id: user.uid,
            email: user.email,
            isAdmin: false,
            isPermitted: false,
            hasAnsweredQuestionaire: false,
            displayName: user.displayName,
            photoUrl: user.photoURL,
            phoneNo: user.phoneNumber,
            ip: location?.ip,
            country: location?.country,
            city: location?.city,
            createdAt: new Date(),
            lastLogin: new Date(),
          };
          await setDoc(userDocRef, userDataToStore);
          await setDoc(chatRoomDocRef, {
            id: 1,
            name: "Finance Chat",
            messages: [],
            userId: user.uid,
            region: "UK",
            model: "Model V2",
          });
          await setDoc(newsLetterDocRef, {
            email: user.email,
            id: newsLetterDocRef.id,
          });
          setUser({
            ...userDocSnapshot.data(),
            id: user.uid,
            email: user.email,
            photoUrl: user.photoURL,
            hasAnsweredQuestionaire: false,
            isAdmin: false,
            isPermitted: false,
          });
          setLoading(false);
        } else {
          // Update user document with location details
          await updateDoc(userDocRef, {
            ip: location?.ip,
            country: location?.country,
            city: location?.city,
            lastLogin: new Date(),
          });

          if (
            localStorage.getItem("user") !== null ||
            (localStorage.getItem("user") === undefined &&
              !deepEqual(
                JSON.parse(localStorage.getItem("user")),
                userDocSnapshot.data()
              ))
          ) {
            localStorage.removeItem("user");
            setUser({
              ...userDocSnapshot.data(),
            });
            setLoading(false);
          } else {
            setUser({
              ...userDocSnapshot.data(),
            });
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error=" + error);
      });
  };

  const getCurrentUser = async () => {
    const userDocRef = doc(db, "Users", user.id);
    const userResponse = await getDoc(userDocRef);
    setUser({ ...userResponse.data() });
  };

  const getAllUsersList = async () => {
    const userDocRef = collection(db, "Users");
    const data = await getDocs(userDocRef);
    return data.docs;
  };

  const updateCurrentUser = async (data) => {
    const userDocRef = doc(db, "Users", user.id);
    await setDoc(userDocRef, data);
  };

  const updateMessageData = async (roomId, data) => {
    const userDocRef = doc(db, "ChatRooms", roomId);
    await setDoc(userDocRef, data);
  };

  const addRoom = async (name) => {
    const chatRoomDocRef = doc(collection(db, "ChatRooms"));
    await setDoc(chatRoomDocRef, {
      id: chatRoomDocRef.id,
      name: name,
      messages: [],
      userId: user.id,
      region: "UK",
      model: "Model V2",
      createdAt: serverTimestamp(),
    });
    const userDocSnapshot = await getDoc(chatRoomDocRef);
    if (userDocSnapshot.exists()) return userDocSnapshot?.id;
  };

  const updateUser = async (id, data) => {
    const userDocRef = doc(db, "Users", id);
    await setDoc(userDocRef, data);
    getAllUsersList();
  };

  const getURLs = async (id) => {
    const userDocRef = collection(db, "Environment Variables");
    const data = await getDocs(userDocRef);
    return data.docs;
  };

  const getUrlsData = async () => {
    try {
      const data = await getURLs();
      const list = [];
      data.forEach((item) => {
        list.push(item.data());
        if (item.data()?.type === "staging") setCurrentURL(item.data());
      });
      setUrlsData(list);
    } catch (error) {}
  };

  const updateQuestionnaire = async (data) => {
    const userDocRef = doc(db, "Questionnaire", user.id);
    await setDoc(userDocRef, {
      ...data,
      userId: user.id,
    });
  };

  const getUserChatRooms = async () => {
    const q = query(
      collection(db, "ChatRooms"),
      where("userId", "==", user?.id),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    return data;
  };

  const logout = () => {
    localStorage.removeItem("user");
    window.location.reload();
    setUser(null);
  };

  const emailSignIn = async (email, password) => {
    try {
      setLoading(true);

      localStorage.removeItem("user");
      // Sign in user with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      // Reference to Firestore document
      const userDocRef = doc(db, "Users", user.uid);

      await updateDoc(userDocRef, {
        ip: location?.ip,
        country: location?.country,
        city: location?.city,
        lastLogin: new Date(),
      });

      // Fetch user details from Firestore
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        localStorage.setItem("user", JSON.stringify(userData));

        setUser(userData);
        setLoading(false);
        return { userData, success: true };
      } else {
        throw new Error("User document does not exist.");
      }
    } catch (error) {
      setLoading(false);
      return { code: error.code, message: error.message };
    }
  };

  //Signup using emailId and password
  const emailSignUp = async (email, password) => {
    try {
      setLoading(true);
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Reference to Firestore document
      const userDocRef = doc(db, "Users", user.uid);

      // Create user data to store in Firestore
      const userDataToStore = {
        id: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoUrl: user.photoURL,
        phoneNo: user.phoneNumber,
        isAdmin: false,
        isPermitted: false,
        hasAnsweredQuestionaire: false,
        ip: location?.ip,
        country: location?.country,
        city: location?.city,
        createdAt: new Date(),
        lastLogin: new Date(),
      };

      // Store user details in Firestore
      await setDoc(userDocRef, userDataToStore);

      // Clear previous user data from localStorage
      localStorage.removeItem("user");

      localStorage.setItem("user", JSON.stringify(userDataToStore));

      setUser(userDataToStore);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return { code: error.code, message: error.message };
    }
  };

  //to send the verification email to user when he enter his signUp
  const emailVerification = () => {
    return sendEmailVerification(auth.currentUser);
  };

  return (
    <UserAuthContext.Provider
      value={{
        user,
        timeActive,
        logout,
        getURLs,
        currentURL,
        testingUrl,
        setTesingUrl,
        urlsData,
        setUrlsData,
        setCurrentURL,
        setTimeActive,
        getUserChatRooms,
        updateQuestionnaire,
        updateCurrentUser,
        updateUser,
        getCurrentUser,
        updateMessageData,
        addRoom,
        googleSignin,
        emailSignIn,
        emailSignUp,
        timeActive,
        setTimeActive,
        emailVerification,
        loading,
        getAllUsersList,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

//custome useContext hook
export const useUserAuthContext = () => {
  return useContext(UserAuthContext);
};
