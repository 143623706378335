import React, { useState, useEffect } from "react";
import google from "../images/googleicon.webp";

import { GrClose } from "react-icons/gr";
import "./app.css"

const LoginCard = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Adding a small delay to trigger the animation
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50  transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`relative loginCard p-6 rounded-lg shadow-lg w-full max-w-sm mx-auto transition-transform duration-300 ${
          isVisible ? "translate-y-0" : "-translate-y-10"
        }`}
      >
        <h2 className="text-2xl text-[#7e7e7e] font-medium text-center mb-4">
          Login
        </h2>
        <button
          type="button"
          className="w-full text-gray-500 py-3 hover:bg-[#f3f8ff]  border border-[#C1D5F6] rounded-full transition flex items-center justify-center"
        >
          <img className="h-6 mr-2" src={google} alt="" />
          Login with Google
        </button>
        <div className="flex items-center my-4">
          <hr className="flex-grow border-t border-gray-300" />
          <span className="mx-4 text-lg text-gray-500">or</span>
          <hr className="flex-grow border-t border-gray-300" />
        </div>
        <form>
          <div className="mb-4">
            <input
              placeholder="Email"
              type="email"
              id="email"
              className="mt-1 p-2 w-full border pl-7 border-gray-300 rounded-full focus:outline-none focus:ring-1 focus:ring-blue-200"
            />
          </div>
          <div className="mb-4">
            <input
              placeholder="Password"
              type="password"
              id="password"
              className="mt-1 p-2 w-full border pl-7 border-gray-300 rounded-full focus:outline-none focus:ring-1 focus:ring-blue-200"
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="w-full bg-[#96BBFE] text-white py-3 font-semibold text-lg border rounded-full hover:bg-[#84a7e8] transition"
            >
              Login
            </button>
          </div>
        </form>
        <div className="text-xs text-[#888888] text-center mt-1">
          By continuing you accept our{" "}
          <span className="text-[#96BBFE] cursor-pointer ">
            Terms & Conditions
          </span>{" "}
          and{" "}
          <span className="text-[#96BBFE] cursor-pointer">Privacy Policy</span>
        </div>
        <div className="text-center text-[#888888] mt-5">
          Don’t have an account?{" "}
          <a className="text-[#96BBFE]" href="">
            Sign up
          </a>
        </div>
        <button
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4 text-black p-2 focus:outline-none"
        >
          <GrClose />
        </button>
      </div>
    </div>
  );
};

export default LoginCard;
