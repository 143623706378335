import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, element, adminOnly }) => {
  if (!user) {
    // User not logged in
    return <Navigate to="/" replace />;
  }

  if (adminOnly && !user.isAdmin) {
    // User is not an admin
    return <Navigate to="/" replace />;
  }

  if (!user.isPermitted) {
    // User is not permitted
    return <Navigate to="/" replace />;
  }

  // User meets all conditions
  return element;
};

export default ProtectedRoute;
