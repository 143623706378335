import React, { useRef, useState } from "react";
import styled from "styled-components";
import { IoIosSend } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import { ButtonContainer } from "../styled/Button";
import useChatActions from "../hooks/useChatActions";
import { useChat } from "../context/ChatProvider";
import { useUserAuthContext } from "../context/AuthProvider";
import { auth } from "../firebase";
import useFirebaseAuth from "../hooks/useFirebaseAuth";

const MessageForm = styled.form`
  padding: 0.5vw 0;
  display: flex;
  align-items: center;
  height: 10%;

  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 10px 20px;
  border-radius: 10px;
  & input {
    flex: 1;
    height: 100%;
    color: #000;
    background: #fff;
    width: 100%;
    border: none;
  }
`;

const stringSplitter = (name) => {
  if (name.length > 50) return name.subtring(1, 50);
  return name;
};

export const urlReturner = (currentURL) => {
  if (process.env.REACT_APP_CURRENT_ENVIORONEMNT === "STAGING") {
    return currentURL?.url;
  } else {
    return "https://rise-chatbot-heroku-prod-7a0f0e210c75.herokuapp.com/";
  }
};

const ChatForm = () => {
  const inputRef = useRef(null);
  const { sendMessage } = useChatActions();
  const { updateMessageData, currentURL } = useUserAuthContext();
  const [isLoadingRooms, setLoadingRooms] = useState([]);
  const { user, token } = useFirebaseAuth();
  const { currentRoom, getInstantChatRooms } = useChat();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (inputRef.current.value === "") return;
    const currentRoomId = currentRoom;
    sendMessage(inputRef.current.value, true, "MN");
    setLoadingRooms((prevRooms) => [...prevRooms, currentRoom]);
    const value = inputRef.current.value;
    inputRef.current.value = "";
    await fetch(`${urlReturner(currentURL)}chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        content: value,
        model: currentRoomId.model,
        region: currentRoomId.region,
      }),
    })
      .then((res) => res.json())
      .then(async (data) => {
        sendMessage(data.response, false, "Rise");
        const index = isLoadingRooms.indexOf(currentRoomId);
        const newRooms = isLoadingRooms.splice(index, 1);
        await updateMessageData(currentRoomId.id, {
          ...currentRoomId,
          messages: [...currentRoomId.messages],
          name: stringSplitter(currentRoomId.messages[0].text),
        });
        getInstantChatRooms();
        setLoadingRooms(newRooms);
      })
      .catch(() => {
        const index = isLoadingRooms.indexOf(currentRoomId);
        const newRooms = isLoadingRooms.splice(index, 1);
        setLoadingRooms(newRooms);
        alert("Error Occured while getting response");
      });
    //   if (user) {
    //     try {
    //       const token = await user.getIdToken();
    //       await fetch(`${urlReturner()}chat`, {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           Authorization: `Bearer ${token}`,
    //         },
    //         body: JSON.stringify({
    //           content: value,
    //           model: currentRoomId.model,
    //           region: currentRoomId.region,
    //         }),
    //       })
    //         .then((res) => res.json())
    //         .then(async (data) => {
    //           sendMessage(data.response, false, "Rise");
    //           const index = isLoadingRooms.indexOf(currentRoomId);
    //           const newRooms = isLoadingRooms.splice(index, 1);
    //           await updateMessageData(currentRoomId.id, {
    //             ...currentRoomId,
    //             messages: [...currentRoomId.messages],
    //             name: stringSplitter(currentRoomId.messages[0].text),
    //           });
    //           getInstantChatRooms();
    //           setLoadingRooms(newRooms);
    //         })
    //         .catch(() => {
    //           const index = isLoadingRooms.indexOf(currentRoomId);
    //           const newRooms = isLoadingRooms.splice(index, 1);
    //           setLoadingRooms(newRooms);
    //           alert("Error Occured while getting response");
    //         });
    //     } catch (err) {
    //       // setError(err.message);
    //     }
    //   }
    // });
    inputRef.current.focus();
  };

  return (
    <MessageForm onSubmit={onSubmit}>
      <input type="text" placeholder="Type a message here" ref={inputRef} />

      {!isLoadingRooms.includes(currentRoom) ? (
        <ButtonContainer
          flex="0"
          padding="0"
          active={true}
          size="2.2em"
          borderRadius="50%"
        >
          <button>
            <IoIosSend fill="#fff" />
          </button>
        </ButtonContainer>
      ) : (
        <CircularProgress size={25} />
      )}
    </MessageForm>
  );
};

export default ChatForm;
