import amazon from "../images/companies/amazon.png";
import microsoft from "../images/companies/microsoft.png";
import apple from "../images/companies/apple.png";
import google from "../images/companies/google.png";
import nvidia from "../images/companies/nvidia.png";
import lilly from "../images/companies/lilly.png";
import meta from "../images/companies/meta.png";
import broadcom from "../images/companies/broadcom.png";
import saudi from "../images/companies/saudi.png";
import berkshire from "../images/companies/berkshire.png";

export const suggestionsData = [
  {
    id: 1,
    text: "Microsoft Corporation",
    subtext: "NasdaqGS:MSFT",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: microsoft,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 2,
    text: "Apple Inc.",
    subtext: "NasdaqGS:AAPL",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: apple,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 3,
    text: "Nvidia Corporation",
    subtext: "NasdaqGS:NVDA",
    Listings: "33 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: nvidia,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 4,
    text: "Alphabet Inc.",
    subtext: "NasdaqGS:GOOGL",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: google,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 5,
    text: "Amazon.com, Inc.",
    subtext: "NasdaqGS:AMZN",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: amazon,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 6,
    text: "Saudi Arabian Oil Company",
    subtext: "SASE:2222",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: saudi,
    subtextImageUrl: "https://www.worldometers.info/img/flags/sa-flag.gif",
  },
  {
    id: 7,
    text: "Meta Platforms, Inc.",
    subtext: "NasdaqGS:META",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: meta,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 8,
    text: "Berkshire Hathaway Inc.",
    subtext: "NYSE:BRK.A",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: berkshire,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 9,
    text: "Eli Lilly and Company",
    subtext: "NYSE:LLE",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: lilly,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
  {
    id: 10,
    text: "Broadcom Inc.",
    subtext: "NadaqGS:AVGO",
    Listings: "19 Listings",
    details: [
      {
        text: "DB:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "DB:MSFO",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "XTRA:MSF",
        imageUrl: "https://www.worldometers.info/img/flags/gm-flag.gif",
      },
      {
        text: "SWX:MSFT",
        imageUrl: "https://www.worldometers.info/img/flags/sz-flag.gif",
      },
      {
        text: "LSE:OQYP",
        imageUrl: "https://www.worldometers.info/img/flags/uk-flag.gif",
      },
    ],
    textImageUrl: broadcom,
    subtextImageUrl: "https://www.worldometers.info/img/flags/us-flag.gif",
  },
];

export const searchResults = [
  {
    company: "nvidia",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Nvidia launches new GPU",
        description2: "Nvidia prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        icon: nvidia,
        description1: "Nvidia",
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
  {
    company: "amazon",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Amazon launches new product",
        description2: "Amazon prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        description1: "Amazon",
        icon: amazon,
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
  {
    company: "apple",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Apple launches new product",
        description2: "Apple prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        description1: "Apple",
        icon: apple,
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
  {
    company: "google",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Google launches new product",
        description2: "Google prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        description1: "Google",
        icon: google,
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
  {
    company: "meta",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Meta launches new product",
        description2: "Meta prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        description1: "Meta",
        icon: meta,
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
  {
    company: "microsoft",
    results: [
      {
        heading: "Search with Co-Pilot",
        description1: "Microsoft launches new product",
        description2: "Microsoft prepares for AI boom",
        description3: "",
      },
      {
        heading: "Securities",
        description1: "microsoft",
        icon: microsoft,
        description2: "",
        description3: "",
      },
      {
        heading: "Functions",
        description1: "Price",
        description2: "Financials",
        description3: "News",
      },
    ],
  },
];
