import React, { useEffect, useState } from "react";
import styled from "styled-components";
import googleLogo from "../google_icon.svg";
import { useUserAuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router";
import logo from "../resources/logo.webp";
import { Link } from "react-router-dom";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 50%;
  max-width: 70%;
  margin: auto;
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #f8f9fa;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  @media (max-width: 480px) {
    padding: 15px;
    width: 90%;
    max-width: 95%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const LogoTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: #344559;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const Logo = styled.img`
  max-width: 40px;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 480px) {
    max-width: 40px;
    border-radius: 8px;
  }
`;

const Heading = styled.h2`
  color: #7e7e7e;
  margin-bottom: 20px;
`;

const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 60%;
  padding: 10px;
  background: #ffffff;
  color: #9c9c9c;
  border: 1px solid #c1d5f6;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #f1f1f1;
  }
  @media (max-width: 480px) {
    width: 90%;
  }
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0px;
  width: 80%;
  color: #888888;
  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background: #e4e4e4;
  }
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
`;

const EmailPasswordForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 60%;
  padding: 10px 30px;
  margin: 10px auto;
  border: 1px solid #d4d4d4;
  border-radius: 25px;
  font-size: 16px;
  color: #888888;
  @media (max-width: 480px) {
    font-size: 14px;
    width: 80%;
  }
`;

// const ForgotPassword = styled.a`
//   align-self: flex-end;
//   // margin: -5px 0 15px;
//   margin: -5px 130px 15px;
//   font-size: 14px;
//   color: #5e83e2;
//   cursor: pointer;
//   text-decoration: none;
//   &:hover {
//     text-decoration: underline;
//   }
//   @media (max-width: 480px) {
//     width: 80%;
//     margin: -5px -70px 15px;
//   }
// `;

const LoginButton = styled.button`
  width: 50%;
  padding: 10px;
  margin: 0 auto;
  background: #96bbfe;
  border: none;
  border-radius: 25px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: #84a6e6;
  }
  @media (max-width: 480px) {
    width: 80%;
  }
`;

const TermsText = styled.p`
  font-size: 12px;
  width: 50%;
  margin-top: 20px;
  text-align: left;
  color: #888888;
  & a {
    color: #96bbfe;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 480px) {
    width: 80%;
  }
`;

const SignUpText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: left;
  color: #888888;
  & a {
    color: #5e83e2;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ErrorMsg = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  color: #ff0000;
`;

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [cPass, setCPass] = useState("");
  const [error, setError] = useState("");

  const { emailSignUp, emailVerification, googleSignin, user, loading } =
    useUserAuthContext();

  //If we already have the user, then re-direct back to dashboard
  useEffect(() => {
    if (user) {
      if (user.isPermitted || user.isAdmin) {
        navigate("/dashboard");
      } else {
        navigate("/");
      }
    }
  }, [user]);
  const navigate = useNavigate();

  const validatePassword = () => {
    let isValid = true;
    if (pass !== "" && cPass !== "") {
      if (pass !== cPass) {
        isValid = false;
        setError("Passwords does not match");
      } else if (pass.length < 8) {
        isValid = false;
        setError("Passwords should be minimum 8 character long.");
      }
    }
    return isValid;
  };

  const signupSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous error messages

    if (validatePassword()) {
      try {
        const res = await emailSignUp(email, pass);

        if (res.success) {
          try {
            await emailVerification();
            navigate("/");
          } catch (verificationError) {
            console.error("Email verification error:", verificationError);
            setError("Something unexpected happened! Please try again later.");
          }
        } else {
          // Handle different error codes
          switch (res.code) {
            case "auth/email-already-in-use":
              setError(
                "Email is already registered! Please use a different email."
              );
              break;
            case "auth/invalid-email":
              setError("Invalid email format! Please enter a valid email.");
              break;
            case "auth/weak-password":
              setError("Password is too weak! Please use a stronger password.");
              break;
            default:
              setError("An unexpected error occurred. Please try again later.");
              break;
          }
        }
      } catch (err) {
        console.error("Sign up error:", err);
        setError("An unexpected error occurred. Please try again later.");
      }
    }
  };

  if (loading) {
    return <h2>Loading ....</h2>;
  }

  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt="Logo" />
        <LogoTitle>Rise AI</LogoTitle>
      </LogoContainer>

      <Heading>Sign Up</Heading>
      <GoogleButton onClick={googleSignin}>
        <img src={googleLogo} alt="Google" style={{ width: "20px" }} />
        Sign Up with Google
      </GoogleButton>

      <Separator>OR</Separator>

      <EmailPasswordForm type="submit" onSubmit={signupSubmit}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          required
          minLength={8}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={cPass}
          onChange={(e) => setCPass(e.target.value)}
          required
          minLength={8}
        />
        <LoginButton type="submit">Sign up</LoginButton>
      </EmailPasswordForm>

      <TermsText>
        By continuing you accept our <Link to="/terms">Terms & Conditions</Link>{" "}
        and <Link to="/privacy">Privacy Policy</Link>
      </TermsText>

      <SignUpText>
        Already have an account? <Link to="/">Login</Link>
      </SignUpText>
      {error && <ErrorMsg>{error}</ErrorMsg>}
    </Container>
  );
};

export default SignUp;
