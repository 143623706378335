import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import logo from "../images/Rise.svg";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [backgroundwhite, setBackgroundWhite] = useState(false);
  const navigate = useNavigate();
  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  // navbar background change
  const handleWindowScroll = (e) => {
    const height = window.scrollY;
    const tresholdHeight = 50;

    if (height > tresholdHeight) {
      setBackgroundWhite(true);
    } else {
      setBackgroundWhite(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);

    return () => window.removeEventListener("scroll", handleWindowScroll);
  }, []);

  return (
    <header
      className={classNames(
        "fixed  w-full transition-all duration-700 z-30  ",
        {
          "backdrop-blur backdrop-filter shadow-lg rounded-br-2xl rounded-bl-2xl":
            backgroundwhite,
        }
      )}
    >
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block">
              <img className="w-[64px]" src={logo} alt="logo" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex ">
            {/* Desktop sign in links */}
            <ul className="flex ml-10  items-center">
              <li></li>
              <li>
                <a
                  href="/#about"
                  className="font-medium text-primary-color hover:text-hover-color px-4 py-3 transition duration-150 ease-in-out"
                >
                  About
                </a>
                <a
                  href="/#pricing"
                  className="font-medium cursor-pointer text-primary-color hover:text-hover-color px-4 py-3  transition duration-150 ease-in-out"
                >
                  Pricing
                </a>
                <a
                  href="mailto:contact@riseai.app"
                  className="font-medium text-primary-color hover:text-hover-color px-4 py-3   transition duration-150 ease-in-out"
                >
                  Contact Us
                </a>
                <Link
                  to="/privacy"
                  className="font-medium text-primary-color hover:text-hover-color px-4 py-3  transition duration-150 ease-in-out"
                >
                  Privacy Policy
                </Link>
                <button className="bg-[#344559] px-5 shadow-md hover:bg-slate-600 rounded-full p-2 text-white text-base">
                  <a
                    onClick={() => navigate("/login")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Login
                  </a>
                </button>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="md:hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-secondary-color hover:text-hover-color transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <nav
              id="mobile-nav"
              ref={mobileNav}
              className="absolute top-full rounded-lg z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
              style={
                mobileNavOpen
                  ? { maxHeight: mobileNav.current.scrollHeight, opacity: 1 }
                  : { maxHeight: 0, opacity: 0.8 }
              }
            >
              <ul className="bg-primary-color rounded-lg px-4 py-2">
                <li>
                  <a
                    href="/#about"
                    className="flex font-medium w-full text-slate-200 hover:text-gray-200 py-2 justify-center"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="/#pricing"
                    className="flex font-medium w-full text-slate-200 hover:text-gray-200 py-2 justify-center"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contact@riseai.app"
                    className="flex font-medium w-full text-slate-200 hover:text-gray-200 py-2 justify-center"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link
                    to="/privacy"
                    className="flex font-medium w-full text-slate-200 hover:text-gray-200 py-2 justify-center  "
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <a
                    className="font-medium"
                    // href="htt"
                    onClick={() => navigate("/login")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="bg-white px-5 w-full flex justify-center shadow-md font-medium hover:bg-slate-600 rounded-full p-2 text-[#344559] text-base">
                      Login
                    </button>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
