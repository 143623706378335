import React, { useState } from "react";
import styled from "styled-components";
import { FaBell } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import {
  Avatar,
  Modal,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import Portfolio from "../pages/Portfolio";
import { SidebarMenu } from "../components/Sidebar";
import ChatCoPilot from "../components/ChatCoPilot";
import MainDashboard from "./MainDashboard";
import { IoMenu } from "react-icons/io5";
import { BiSearch } from "react-icons/bi";
import { MdNotifications } from "react-icons/md";
import logo from "../resources/logo.webp";
import { IoMdTrendingUp } from "react-icons/io";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TbLeaf } from "react-icons/tb";
import { TbHammer } from "react-icons/tb";
import { AiOutlineBank } from "react-icons/ai";
import { CiBank } from "react-icons/ci";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { MdOutlineInsertChart } from "react-icons/md";
import { BsHouse } from "react-icons/bs";
import { FaBitcoin } from "react-icons/fa";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { useUserAuthContext } from "../context/AuthProvider";
import ukFlag from "../resources/uk.png";
import profilePic from "../resources/profile.png";

const ChatAppContainer = styled.div`
  --vertical-padding: 3vh;
  display: flex;
  margin: auto;
  background: #edf5ef;
  height: 100%;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  @media (max-width: 820px) {
    position: relative;
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 0;
    font-size: 0.85rem;
    margin: auto;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 1.5vw;
  margin-left: 20px;
  // flex-direction: column;
  // height: 100%;
  // margin-top: 25px;

  @media (max-width: 820px) {
    // height: 100%;
    margin-left: 0px;
    // margin-top: 0;
  }
`;

const Img = styled.img`
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 2px;
  background-color: #009fdb;
  margin-right: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: #000;
  @media (max-width: 820px) {
    font-size: 14px;
  }
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  padding: 0.5rem;
  @media (max-width: 820px) {
    width: 200px;
    margin-left: 1rem;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  margin-left: 0.5rem;
  background: #fafafa;
`;

const MenuIconContainer = styled.div`
  margin: 5px 0 5px 10px;
  cursor: pointer;
`;

const LargeScreenHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
  background-color: white;
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem; /* Add spacing between elements */
`;

const NotificationContainer = styled.div`
  position: relative;
  background: #f0f4ff;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const ProfileName = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #333;
`;

const ProfileRole = styled.span`
  font-size: 14px;
  color: #777;
`;

const RegionSelect = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-right: 8px;
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

const Dashboard = () => {
  const { user } = useUserAuthContext();
  const [isNavOpen, setIsNavOpen] = useState();
  const initialScreen = "Rise CoPilot";
  const [showScreen, setShowCurrentScreen] = useState(initialScreen);
  const [toggled, setToggled] = useState(false);
  const [query, setQuery] = useState("");
  const smUp = useMediaQuery("(max-width : 900px)");
  const [isModalOpen, setIsModalOpen] = useState(false); //To show the message for non-permitted users

  const boxes = [
    {
      title: "Equity ETFs ",
      subtitle: "Equities",
      icon: IoMdTrendingUp,
      bgColor: "#DFE9EF",
    },
    {
      title: "Bond ETFs",
      subtitle: "Fixed Income",
      icon: CiMoneyCheck1,
      bgColor: "#E1FFDE",
    },
    {
      title: "Sustainable ETFs",
      subtitle: "Green Investments",
      icon: TbLeaf,
      bgColor: "#CCF9F9",
    },
    {
      title: "Commodity ETFs",
      subtitle: "Physical Assets",
      icon: TbHammer,
      bgColor: "#E7DBFF",
    },
    {
      title: "Stocks",
      subtitle: "Public Shares",
      icon: AiOutlineBank,
      bgColor: "#FCE6D5",
    },
    {
      title: "Bonds",
      subtitle: "Fixed Returns",
      icon: CiBank,
      bgColor: "#E0F2FF",
    },
    {
      title: "Currencies (FX)",
      subtitle: "Foreign Exchange",
      icon: HiOutlineCurrencyDollar,
      bgColor: "#FFE0F6",
    },
    {
      title: "Macro Economic Data",
      subtitle: "Economic Indicators",
      icon: MdOutlineInsertChart,
      bgColor: "#DBFFCA",
    },
    {
      title: "Real Estate",
      subtitle: "Property Investments",
      icon: BsHouse,
      bgColor: "#FEFFBF",
    },
    {
      title: "Crypto",
      subtitle: "Digital Currencies",
      icon: FaBitcoin,
      bgColor: "#FFEDD1",
    },
    {
      title: "Private Companies",
      subtitle: "Unlisted Firms",
      icon: HiOutlineBuildingOffice2,
      bgColor: "#FFD2F5",
    },
    {
      title: "News",
      subtitle: "Market Updates",
      icon: HiOutlineNewspaper,
      bgColor: "#FFD6D6",
    },
  ];

  //To show the only chat screen if user is not permitted:
  const handleMenuItemClick = (screen) => {
    if (user.isPermitted) {
      setShowCurrentScreen(screen);
      setToggled(false);
    } else {
      setIsModalOpen(true);
      setToggled(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const filteredBoxes = boxes.filter(
    (box) => box.title.toLowerCase().includes(query.toLowerCase())
    // || box.subtitle.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <>
      <ChatAppContainer>
        <Modal
          open={isModalOpen || !user.isPermitted}
          onClose={closeModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 350,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Ooops!
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              You currently do not have Beta access - please contact us at{" "}
              <a
                href="mailto:contact@riseai.app?subject=Beta%20Access%20Request"
                style={{ color: "#344559", borderBottom: "1px solid #344559" }}
              >
                contact@riseai.app
              </a>{" "}
              with the subject “Beta Access”.
            </Typography>
            <Button onClick={closeModal} sx={{ mt: 2 }} variant="contained">
              Close
            </Button>
          </Box>
        </Modal>
        {smUp && (
          <Header>
            <LogoContainer>
              <Img src={logo} alt="LOGO" />
              <Title>Rise AI</Title>
            </LogoContainer>
            {showScreen === "Dashboard" && (
              <SearchBarContainer>
                <BiSearch style={{ color: "#009fdb" }} />
                <SearchInput
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="search here"
                />
              </SearchBarContainer>
            )}
            <MenuIconContainer>
              <IoMenu
                size={25}
                color={`#000`}
                onClick={() => setToggled(true)}
              />
            </MenuIconContainer>
          </Header>
        )}
        <SidebarMenu
          showScreen={showScreen}
          toggled={toggled}
          setToggled={setToggled}
          openRoomNav={() => setIsNavOpen(true)}
          setShowCurrentScreen={handleMenuItemClick}
        />

        {/* Portfolio page -> Dashboard
            MainDashboard page -> Portfolio
        */}
        <div style={{ width: "100%", height: "100vh" }}>
          {!smUp && (
            <LargeScreenHeader>
              <TitleContainer>
                <Typography variant="h6">{showScreen}</Typography>
              </TitleContainer>
              {showScreen === "Dashboard" && (
                <SearchBarContainer>
                  <BiSearch style={{ color: "#009fdb" }} />
                  <SearchInput
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search here..."
                  />
                </SearchBarContainer>
              )}
              <RightContainer>
                <RegionSelect>
                  <img src={ukFlag} alt="UK Flag" />
                  <span>Region (UK)</span>
                  <FiChevronDown style={{ marginLeft: 5 }} />
                </RegionSelect>
                <NotificationContainer>
                  <FaBell size={20} />
                </NotificationContainer>
                <ProfileContainer>
                  <Avatar
                    src={user?.photoUrl}
                    alt="Profile"
                    style={{ width: 40, height: 40, borderRadius: "8px" }}
                  />
                  <ProfileDetails>
                    <ProfileName>{user?.displayName}</ProfileName>
                    <ProfileRole>
                      {user?.isAdmin ? "Admin" : "User"}
                    </ProfileRole>
                  </ProfileDetails>
                  <FiChevronDown style={{ marginLeft: 5 }} />
                </ProfileContainer>
              </RightContainer>
            </LargeScreenHeader>
          )}
          {showScreen === "Portfolio" && user.isPermitted && (
            <CenterContainer>
              <MainDashboard />
            </CenterContainer>
          )}
          {showScreen === "Rise CoPilot" && user.isPermitted && (
            <CenterContainer>
              <ChatCoPilot />
            </CenterContainer>
          )}
          {showScreen === "Dashboard" && user.isPermitted && (
            <CenterContainer>
              <Portfolio boxes={filteredBoxes} />
            </CenterContainer>
          )}
        </div>
      </ChatAppContainer>
    </>
  );
};

export default Dashboard;
