import React, { useState, useRef, useEffect } from "react";
import { suggestionsData, searchResults } from "../utils/Data";
import LoginCard from "./LoginCard";
import star from "../images/star.png";
import { FiExternalLink } from "react-icons/fi";
import { useNavigate } from "react-router";

const Search = () => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [expandedSuggestion, setExpandedSuggestion] = useState(null);
  const suggestionRef = useRef(null);
  const inputRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [prevSearchText, setPrevSearchText] = useState("");
  const navigate = useNavigate();
  const handleOpenModal = () => {
    setPrevSearchText(searchText);
    setSearchText("");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSearchText(prevSearchText);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        suggestionRef.current &&
        !suggestionRef.current.contains(e.target) &&
        e.target !== inputRef.current
      ) {
        setShowSuggestions(false);
        setExpandedSuggestion(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (id) => {
    setExpandedSuggestion((prevId) => (prevId === id ? null : id));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate("/login");
      setShowSuggestions(false);
      setExpandedSuggestion(null);
      inputRef.current.blur();
    }
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredResults = searchResults.filter((result) =>
    searchText.toLowerCase().includes(result.company.toLowerCase())
  );

  return (
    <div className="relative flex flex-col items-center justify-center md:my-16 my-4 bg-[#EDF5EF]">
      <div className="relative z-20 w-11/12 md:w-4/5">
        <input
          ref={inputRef}
          type="search"
          inputMode="search"
          className="focus:outline-none text-base md:text-lg placeholder-gray-600 focus:ring-0 shadow-md focus:border-transparent rounded-full border-transparent bg-gradient-to-r p-3 md:p-4 w-full focus:outline-0 from-yellow-100 via-amber-200 to-orange-200"
          placeholder="Search your next investment idea..."
          value={searchText}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />
      </div>

      {showSuggestions && (
        <div
          ref={suggestionRef}
          className="absolute md:w-4/5 top-16 mt-1 bg-white w-11/12 rounded-lg shadow-lg z-10 max-h-80 overflow-y-auto"
        >
          <ul>
            {filteredResults.length > 0 && (
              <li className="relative mb-4 ">
                <div className="p-2">
                  {filteredResults[0].results.map((result, index) => (
                    <div
                      key={index}
                      className={`mb-4 bg-indigo-50 shadow-md   p-2 rounded-md   ${
                        !result.description1 &&
                        !result.description2 &&
                        !result.description3
                          ? "hidden"
                          : ""
                      }`}
                    >
                      <div className="flex  items-center p-2 font-semibold">
                        <span>{result.heading}</span>
                        {result.heading === "Search with Co-Pilot" && (
                          <span className="ml-2">
                            <img className="h-[30px]" src={star} alt="icon" />
                          </span>
                        )}
                      </div>

                      {result.heading === "Search with Co-Pilot" ? (
                        <>
                          {result.description1 && (
                            <a
                              href=""
                              className="flex items-center p-2 text-sm text-blue-500 gap-2 underline"
                            >
                              {result.description1}
                              <FiExternalLink />
                            </a>
                          )}
                          {result.description2 && (
                            <a
                              href=""
                              className="flex items-center p-2 text-sm gap-2 text-blue-500 underline"
                            >
                              {result.description2}
                              <FiExternalLink />
                            </a>
                          )}
                          {result.description3 && (
                            <a
                              href=""
                              className="flex items-center p-2 text-sm gap-2 text-blue-500 underline"
                            >
                              {result.description3}
                              <FiExternalLink />
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          {result.description1 && (
                            <div className="flex items-center p-2 font-medium cursor-pointer text-base text-gray-500">
                              {result.heading === "Securities" && (
                                <img
                                  src={result.icon}
                                  alt=""
                                  className="w-6 h-6 mr-2 rounded-full"
                                />
                              )}
                              <span>{result.description1}</span>
                            </div>
                          )}
                          {result.description2 && (
                            <span className="block p-2 font-medium cursor-pointer text-base text-gray-500">
                              {result.description2}
                            </span>
                          )}
                          {result.description3 && (
                            <span className="block p-2 font-medium text-base cursor-pointer text-gray-500">
                              {result.description3}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </li>
            )}

            <li>
              <span className="block p-2 font-semibold">Companies</span>
              <ul>
                {suggestionsData.map((suggestion) => (
                  <li key={suggestion.id} className="relative">
                    <div
                      className="p-2 hover:bg-gray-200 cursor-pointer flex justify-between items-center"
                      onClick={() => handleSuggestionClick(suggestion.id)}
                      tabIndex={0}
                    >
                      <div className="flex items-center">
                        <img
                          src={suggestion.textImageUrl}
                          alt=""
                          className="w-10 h-10 mr-2 rounded-full"
                        />
                        <div>
                          <div className="flex items-center">
                            <div>{suggestion.text}</div>
                          </div>
                          <div className="flex items-center text-sm text-gray-500">
                            <img
                              src={suggestion.subtextImageUrl}
                              alt=""
                              className="w-4 h-4 mr-2 rounded-full"
                            />
                            {suggestion.subtext}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      )}
      <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-3 mt-5 md:w-4/5 w-11/12">
        <span className="card shadow-sm rounded-md md:py-3 py-2 text-sm text-center cursor-pointer bg-white">
          Show me stocks in North America with the highest yield
        </span>
        <span className="card shadow-sm rounded-md md:py-3 py-2 text-sm text-center cursor-pointer bg-white">
          Why has Snowflake's net revenue been decreasing?
        </span>
        <span className="card shadow-sm rounded-md md:py-3 py-2 text-sm text-center cursor-pointer bg-white">
          What are some popular ETFs that track the S&P 500?
        </span>
        <span className="card shadow-sm rounded-md md:py-3 py-2 text-sm text-center cursor-pointer bg-white">
          What is BlackRock's view on interest rates?
        </span>
      </div>
      {isModalOpen && <LoginCard onClose={handleCloseModal} />}
    </div>
  );
};

export default Search;
