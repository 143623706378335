import React from "react";
import { CgDanger } from "react-icons/cg";
import "../../css/dashboard.css";

const SecondSection = ({
  data2,
  data3,
  sliderBackground,
  sliderBackground2,
}) => {
  return (
    <div className="section_second">
      <div className="section_second_table">
        <label>Return Analysis by Rise</label>
        <table>
          <thead>
            <tr>
              <th className="header">{"Portfolio Level"}</th>
              <th className="small_box header_digit">{"+20%"}</th>
            </tr>
            <tr className="tr">
              <div className="bar_line" />
            </tr>
          </thead>
          <tbody>
            {data2?.map((item, index) => (
              <tr key={index}>
                <td className="td">{item.name}</td>
                <td
                  className="small_box td"
                  style={{
                    backgroundColor: item.weight.includes("+")
                      ? "#B4FFCE"
                      : item.weight.includes("-")
                      ? "#FFC6B5"
                      : "transparent",
                    color: item.weight.includes("+")
                      ? "#2EC847"
                      : item.weight.includes("-")
                      ? "#F24E1E"
                      : "inherit",
                  }}
                >
                  {item.weight}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="section_second_box">
        <span className="second_box_header">
          {"Risk Analysis by Rise AI"}
          <CgDanger color="#F24E1E" style={{ marginLeft: "20px" }} />
        </span>
        <div className="risk-container">
          <div className="risk-label">
            {"Overall Risk /"}
            <span>{" Moderate"}</span>
          </div>
          <input
            type="range"
            min="0"
            max="100"
            defaultValue="50"
            className="risk-slider"
            style={sliderBackground}
          />
          <div className="slider-labels">
            <span className="start-label">Less Risk</span>
            <span className="end-label">More Risk</span>
          </div>
        </div>
        <div className="risk-container">
          <div className="risk-label">
            {"Diversification /"}
            <span>{" Good"}</span>
          </div>
          <input
            type="range"
            min="0"
            max="100"
            defaultValue="50"
            className="risk-slider"
            style={sliderBackground2}
          />
          <div className="slider-labels">
            <span className="start-label">Poor</span>
            <span className="end-label">Good</span>
          </div>
        </div>
        <div className="section_second_table" style={{ width: "100%", padding:"0px" }}>
          <table>
            <thead>
              <tr>
                <th className="header_second">{"1 Year Projected Returns"}</th>
                <th className="header_second">{"Current Portfolio (β = 1.2)"}</th>
              </tr>
              <tr>
                <div className="bar_line" />
              </tr>
            </thead>
            <tbody>
              {data3?.map((item, index) => (
                <tr className="tr" key={index}>
                  <td className="tr_label">{item.name}</td>
                  <td className="tr_label">{item.weight}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
