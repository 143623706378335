import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { getFirstLetter } from "../helpers";
import useMessages from "../hooks/useMessages";
import { useChat } from "../context/ChatProvider";
import MatchText from "../SearchProvider/MatchText";
import { useUserAuthContext } from "../context/AuthProvider";
import logo from "./../resources/logo.webp";

const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vh;
  flex: 1;
  padding: 20px 0;
  overflow: auto;
`;

const MessageContent = styled.div`
  display: flex;
  font-size: 0.8em;
  font-weight: 300;
  padding: 0.8em 1em;
  width: fit-content;
  height: fit-content;
`;

const MessageContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #fff;
  font-size: 1rem;
  flex-direction: ${(props) => (props.incomingMessage ? "row" : "row-reverse")};

  ${MessageContent} {
    background: ${(props) => (props.incomingMessage ? "#fff" : "#fff")};
    border: ${(props) =>
      props.incomingMessage ? "none" : "1px solid rgba(0, 0, 0, 0.1)"};
    color: ${(props) => (props.incomingMessage ? "#000" : "#000")};
    box-shadow: ${(props) =>
        props.incomingMessage
          ? "rgba(32, 112, 198, 0.4)"
          : "rgba(0, 0, 0, 0.15)"}
      2px 3px 15px;
    max-width: 90%;
    border-radius: ${(props) =>
      props.incomingMessage ? "0 8px 8px 8px" : "8px 0 8px 8px"};
  }

  @media (max-width: 600px) {
    gap: 5px;
    max-width: 90%;

    ${MessageContent} {
      font-size: 0.7em;
      padding: 0.6em 0.8em;
    }
  }
`;

const UserProfile = styled.div`
  display: flex;
  z-index: revert-layer;
  position: relative;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "${(props) => getFirstLetter(props.content)}";
    display: ${(props) => (props.showAlt ? "grid" : "none")};
    place-content: center;
    padding: 0.5em;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) =>
      props.incomingMessage
        ? "var(--secondry-color-dark-palette)"
        : "var(--primary-color)"};
  }

  @media (max-width: 600px) {
    img {
      object-fit: fill;
      transform: scale(1.1);
    }
  }
`;

const BotMessage = styled.div`
  width: fit-content;
  margin: 0 auto;
  padding: 0.85em 1.7em;
  font-size: 0.7em;
  text-align: center;
  border-radius: 2em;
  background: rgba(0, 0, 0, 0.05);
`;

const Conversation = () => {
  const messages = useMessages();
  const chatConversation = useRef(null);
  const { user } = useUserAuthContext();
  // auto scroll to bottom on new message recieve / sent
  useEffect(() => {
    chatConversation.current.scrollTo(0, chatConversation.current.scrollHeight);
  }, [messages]);

  return (
    <ConversationContainer ref={chatConversation}>
      {messages.map((m, index) => {
        const { text, author, socket_id, id, myMessage } = m;
        const profilePicUrl = myMessage ? user?.photoUrl : logo;
        const showAlt = !profilePicUrl;
        const isBot = author === "BOT" && !socket_id;

        return isBot ? (
          <BotMessage>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>{" "}
          </BotMessage>
        ) : (
          <MessageContainer key={index} incomingMessage={!(myMessage === true)}>
            <UserProfile
              content={author}
              incomingMessage={!(myMessage === true)}
              showAlt={showAlt}
            >
              <div>
                <img src={profilePicUrl} alt={getFirstLetter(author)} />
              </div>
            </UserProfile>
            <MessageContent>
              <pre>
                <MatchText id={index}>{text}</MatchText>
              </pre>
            </MessageContent>
          </MessageContainer>
        );
      })}
    </ConversationContainer>
  );
};

export default Conversation;
