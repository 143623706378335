import React from "react";
import { Link } from "react-router-dom";
import "../css/Posts.css";
// import Header from "./header";
// import Footer from "./footer";
// import Navbar from "./navbar";

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Header />
      {/* <Navbar /> */}
      <div className="layout-body">
        <div>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

const Header = () => {
  return (
    <div className="header">
      {/* <h1>My Blog</h1> */}
      <nav className="navbar">
        <Link className="links" to="/blogs">
          Blogs <span>&#x25BC;</span>
        </Link>
        <Link className="links" to="/podcasts">
          Podcasts <span>&#x25BC;</span>
        </Link>
        <Link className="links" to="/newsletters">
          Newsletters <span>&#x25BC;</span>
        </Link>
        <Link className="links" to="/resources">
          Resources <span>&#x25BC;</span>
        </Link>
        <div className="search-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path d="M10 2a8 8 0 106.32 13.906l5.387 5.387a1 1 0 001.415-1.414l-5.387-5.387A8 8 0 0010 2zm0 2a6 6 0 100 12 6 6 0 000-12z" />
          </svg>
        </div>
      </nav>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <p>
          Subscribe to our news letter to get <br />
          latest updates and news
        </p>
        <div className="subscribe">
          <input type="email" placeholder="Email" />
          <button>Subscribe</button>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-section">
          <h3>My Blog</h3>
          <p>
            Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do
            Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Ut Enim
            Ad Minim Veniam, Quis Nostrud Exercitation Ullamco Laboris
          </p>
        </div>
        <div className="footer-section">
          <h3>Categories</h3>
          <ul>
            <li>Investment Tech</li>
            <li>Open AI Tutorials</li>
            <li>Llama3 tutorials</li>
            <li>Large Language Models</li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>Terms and conditions</li>
            <li>Privacy Policy</li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; MY BLOG 2024, All rights reserved</p>
      </div>
    </footer>
  );
};

const Navbar = () => {
  return (
    <div className="navbar">
      <Link className="links" to="/">
        Home
      </Link>
      <Link className="links" to="/about">
        About
      </Link>
    </div>
  );
};
