import React, { useState } from "react";
import chat from "../images/chat.webp";
import newsletter from "../images/newsletter.webp";
import Spincuber from "../utils/CubeSpin.jsx/CubeSpin";

function FeaturesZigzag() {
  const [activeTab, setActiveTab] = useState("features");
  return (
    <section id="how_it_works" className="gradient-bg2">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4 text-primary-color">
              Shh.. Your new secret weapon (an analyst that never gets tired)
            </h2>
            <p className="text-xl text-secondary-color">
              We're breaking down the barriers to investment outperformance. Our
              AI-powered research Co-Pilot and institutional quality data will
              supercharge your investment research, 24/7.
            </p>
          </div>

          {/* switched tabs */}

          <div className="container mx-auto mb-10 p-4">
            <h2 className="h2 text-center   mb-8 text-primary-color">
              Built by Investors and Technologists
            </h2>
            {/* Tab Switch */}
            <div className="flex justify-center  mb-10">
              <span className="bg-white rounded-full p-4 shadow-md">
                {" "}
                <button
                  onClick={() => setActiveTab("features")}
                  className={`px-4  py-2 rounded-full ${
                    activeTab === "features"
                      ? "text-[#344559] shadow-md font-medium  from-indigo-200  via-purple-200 to-pink-200  bg-gradient-to-r"
                      : "text-[#344559] bg-transparent"
                  }  rounded-full `}
                >
                  Primary Features
                </button>
                <button
                  onClick={() => setActiveTab("co-pilot")}
                  className={`px-4 py-2 rounded-full ${
                    activeTab === "co-pilot"
                      ? "text-[#344559] shadow-md font-medium  from-indigo-200  via-purple-200 to-pink-200  bg-gradient-to-r"
                      : "text-[#344559] bg-transparent"
                  }  rounded-full`}
                >
                  Rise AI Co-Pilot
                </button>
              </span>
            </div>

            {/* Content based on active tab */}

            {activeTab === "features" && (
              <div className="flex md:flex-row flex-col gap-12 md:gap-12 items-center">
                {/* Content */}
                <div
                  className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                  data-aos="fade-right"
                >
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 mb-3 text-primary-color">
                      Primary Features
                    </h3>
                    <ul className="text-lg text-secondary-color -mb-2 mt-1">
                      <li className="flex items-center mb-6">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Rise AI Co-Pilot (as featured on the GPT store - loved
                          by 20,000+ users 💙)
                        </span>
                      </li>
                      <li className="flex items-center mb-6">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Integrated Data Terminal - access comprehensive data
                          and analytics for stocks, ETFs, bonds, indices, FX,
                          commodities, company data, macroeconomic data, and
                          crypto data
                        </span>
                      </li>
                      <li className="flex items-center">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Advanced Data Visualization - Utilize cutting-edge AI
                          to build summaries, models, and visualizations to
                          enhance your investment research. Back-test your
                          portfolio, analyze risk, and build a strong portfolio
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Image */}
                <div
                  className="max-w-xl md:max-w-none md:w-full md:h-[400px] h-[300px] md:col-span-5 lg:col-span-6 md:order-1"
                  data-aos="fade-up"
                >
                  <Spincuber />
                </div>
              </div>
            )}

            {activeTab === "co-pilot" && (
              <div className="flex md:flex-row flex-col gap-6 md:gap-12 items-center">
                {/* Image */}
                <div
                  className="max-w-xl md:max-w-none md:w-full md:col-span-5 lg:col-span-6"
                  data-aos="fade-up"
                >
                  <img
                    src={chat}
                    className="md:h-[400px] h-[250px]"
                    alt="Chat"
                  />
                </div>
                {/* Content */}
                <div
                  className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                  data-aos="fade-right"
                >
                  <div className="md:pr-4 lg:pr-12 xl:pr-16">
                    <h3 className="h3 mb-3 text-primary-color">
                      Rise AI Co-Pilot
                    </h3>
                    <ul className="text-lg text-secondary-color -mb-2 mt-1">
                      <li className="flex items-center mb-6">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Let your new AI research analyst find the data you
                          need for your analyses
                        </span>
                      </li>
                      <li className="flex items-center mb-6">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Receive up-to-the-minute market data and data-driven
                          insights tailored to your investment strategies
                        </span>
                      </li>
                      <li className="flex items-center">
                        <svg
                          className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                          Stay ahead with proactive notifications about market
                          changes, investment opportunities, and potential
                          risks, ensuring you're always informed and prepared
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Items */}
          <div className="grid gap-20 mt-14">
            {/* 3rd Item */}
            <div className="flex md:flex-row flex-col gap-6   md:gap-12 items-center">
              {/* Image */}
              <div
                className="max-w-xl md:max-w-none md:w-full flex justify-center   md:col-span-5 lg:col-span-6 "
                data-aos="fade-up"
              >
                <img
                  src={newsletter}
                    width="300px"
                    height="auto"
                  className="md:w-[300px] w-[300px] "
                  alt="Mobile Screen"
                />
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6"
                data-aos="fade-right"
              >
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3 text-primary-color">
                    Personalized Weekly Newsletter
                  </h3>
                  <ul className="text-lg text-secondary-color -mb-2 mt-1">
                    <li className="flex items-center mb-6">
                      <svg
                        className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>
                        Get personalized investment insights delivered to your
                        inbox every week with the Rise AI newsletter.
                      </span>
                    </li>
                    <li className="flex items-center mb-6">
                      <svg
                        className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>
                        Stay updated with market trends, investment
                        opportunities, and potential risks tailored to your
                        investment strategies
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="w-3 h-3 fill-current text-blue-500 mr-2 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>
                        Benefit from AI-driven analysis and expert insights,
                        ensuring you make informed financial decisions every day
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesZigzag;
