import React from "react";
import company2 from "../images/company4.webp";
import company1 from "../images/company1.webp";
import company3 from "../images/company5.webp";

const Partners = () => {
  return (
    <section className="bg-[#EEF9F1]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 ">
        <div className="rounded-3xl bg-gradient-to-b  py-20">
          <div className="container mx-auto">
            <div className="text-center max-w-4xl mx-auto mb-4">
              <h2 className=" font-bold h2 text-primary-color mb-4">
                Who We Are
              </h2>
              <p className="text-xl text-secondary-color">
                At Rise AI, our team combines deep expertise in investment
                management and fintech from leading financial institutions.
                We've developed sophisticated models and conducted rigorous
                analyses to drive portfolio growth, giving us the insights to
                create powerful tools for investors.
              </p>
            </div>
            <div className="mx-4 mt-10">
              <div className="flex flex-row justify-center items-center gap-6">
                <div className="flex-1 flex justify-center">
                  <img
                    src={company1}
                    width="100%"
                    height="80px"
                    className="h-[50px] md:h-[150px] object-contain"
                    alt="Company 1"
                  />
                </div>

                <div className="flex-1 flex justify-center">
                  <img
                    src={company2}
                    width="100%"
                    height="80px"
                    className="h-[50px] md:h-[150px] object-contain"
                    alt="Company 2"
                  />
                </div>

                <div className="flex-1 flex justify-center">
                  <img
                    src={company3}
                    width="100%"
                    height="80px"
                    className="h-[80px] md:h-[200px] object-contain"
                    alt="Company 3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
