import React, { createContext, useContext, useEffect, useState } from "react";
// import io from "socket.io-client";
import { useUserAuthContext } from "./AuthProvider";

// const socket = io.connect(process.env.REACT_APP_SERVER || window.location.host);

const ChatContext = createContext();

export const useChat = () => {
  return useContext(ChatContext);
};

export const ChatProvider = ({ children }) => {
  const [rooms, setRooms] = useState([]);
  const [initialLock, setInitialLock] = useState(true);
  const { user, getUserChatRooms } = useUserAuthContext();
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [currentRoom, setCurrentRoom] = useState(rooms[0]);
  const [socket, setSocket] = useState(null);

  const updateMessages = (message) => {
    currentRoom.messages = [...currentRoom.messages, message];
    setRooms([...rooms]);
  };

  const updateRoomField = (value, field) => {
    currentRoom[field] = value;
    setRooms([...rooms]);
  };

  const getChatRooms = async (id) => {
    setLoading(true);
    const querySnapshot = await getUserChatRooms();
    setRooms([...querySnapshot]);
    if (id) {
      setCurrentRoom([...querySnapshot].filter((room) => room.id === id)[0]);
    }
    setLoading(false);
  };

  const getInstantChatRooms = async () => {
    const querySnapshot = await getUserChatRooms();
    setRooms([...querySnapshot]);
  };

  useEffect(() => {
    getChatRooms();
  }, [user]);
  useEffect(() => {
    if (rooms.length >= 1 && initialLock) {
      setCurrentRoom(rooms[0]);
      setInitialLock(false);
    }
  }, [rooms]);

  useEffect(() => {
    const loadSocket = async () => {
      const { default: io } = await import("socket.io-client");
      const newSocket = io.connect(process.env.REACT_APP_SERVER || window.location.host);
      setSocket(newSocket);
    };
    loadSocket();
  }, []);

  const value = {
    socket,
    rooms,
    userName,
    loading,
    getChatRooms,
    getInstantChatRooms,
    updateRoomField,
    setUserName,
    updateMessages,
    setCurrentRoom,
    currentRoom,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
