import { useChat } from "../context/ChatProvider";

const useChatActions = () => {
  const { socket, updateMessages } = useChat();

  const joinRoom = (roomID) => {
    socket.emit("join-room", roomID);
  };

  const leaveRoom = (roomID) => {
    socket.emit("leave-room", roomID);
  };

  const sendMessage = (text, myMessage, author) => {
    if (!text) {
      return;
    }

    updateMessages({
      text,
      author,
      socket_id: 124,
      myMessage,
      id: "123",
    });
    // socket.emit("send-message", { text, roomID, userName });
  };

  return {
    joinRoom,
    sendMessage,
    leaveRoom,
  };
};

export default useChatActions;
