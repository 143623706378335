import React from "react";
import { MdOutlineTrendingUp, MdOutlineTrendingDown } from "react-icons/md";
import css from "../css/portfolio.module.css";
import PortfolioGraph from "../components/PortfolioGraph";

const portfolioGraphdata1 = [
  {
    symbol: "SPY",
    name: "SPDR S&P 500 ETF Trust",
    wtd: "9.3%",
    mtd: "30%",
    ytd: "303%",
  },
  {
    symbol: "IVV",
    name: "Vanguard S&P 500 ETF",
    wtd: "7%",
    mtd: "28.9%",
    ytd: "289.1%",
  },
  {
    symbol: "VOO",
    name: "Vanguard S&P 500 ETF",
    wtd: "5.1%",
    mtd: "21.1%",
    ytd: "201.15%",
  },
  {
    symbol: "VTI",
    name: "Vanguard Total Stock Market ETF",
    wtd: "3.3%",
    mtd: "17.9%",
    ytd: "167%",
  },
  {
    symbol: "QQQ",
    name: "Invesco QQQ Trust Series I",
    wtd: "2.1%",
    mtd: "11.16%",
    ytd: "150%",
  },
];

const portfolioGraphdata2 = [
  {
    symbol: "ZSL",
    name: "Ultrashort Silver -2X ETF",
    wtd: "-10.3%",
    mtd: "-90%",
    ytd: "-203%",
  },
  {
    symbol: "CRDT",
    name: "Simplify Opportunistic Income ETF",
    wtd: "-7%",
    mtd: "-68%",
    ytd: "-168.1%",
  },
  {
    symbol: "EEV",
    name: "Ultrashort MSCI Emerging Markets...",
    wtd: "-5.7%",
    mtd: "-31.1%",
    ytd: "-201.1%",
  },
  {
    symbol: "EUM",
    name: "Short MSCI Emerging Markets -1X...",
    wtd: "-3.5%",
    mtd: "-16.9%",
    ytd: "-157%",
  },
  {
    symbol: "SDP",
    name: "Ultrashort Utilities -2X ETF",
    wtd: "-2.8%",
    mtd: "-10.16%",
    ytd: "-130%",
  },
];

const portfolioGraphotherData1 = {
  title: "Top Movers",
  gradient_top: "#A9EA8A",
  gradient_bottom: "#ffffff",
  icon: MdOutlineTrendingUp,
};

const portfolioGraphotherData2 = {
  title: "Bottom Movers",
  gradient_top: "#FF5655",
  gradient_bottom: "#ffffff",
  icon: MdOutlineTrendingDown,
};

const tableLabels = ["Symbol", "Name", "WTD", "MTD", "YTD"];

const Portfolio = ({boxes}) => {
  return (
    <div style={{width: "100%"}}>
      <div className={css.boxes}>
        {boxes.map((box, i) => {
          const Icon = box.icon;
          return (
            <div className={css.box} key={i}>
              <div className={css.text_div}>
                <h5>{box.subtitle}</h5>
                <h2>{box.title}</h2>
              </div>
              <div
                className={css.icon_div}
                style={{ backgroundColor: box.bgColor }}
              >
                <Icon className={css.icon} />
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="container">
        <TableComponent />
        <GraphComponent />
      </div> */}
      <PortfolioGraph
        tableData={portfolioGraphdata1}
        tableLabels={tableLabels}
        portfolioGraphotherData={portfolioGraphotherData1}
      />
      <PortfolioGraph
        tableData={portfolioGraphdata2}
        tableLabels={tableLabels}
        portfolioGraphotherData={portfolioGraphotherData2}
      />
    </div>
  );
};

export default Portfolio;
