import React from "react";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import postlist from "../../pages.json";
import Layout from "./Layout";
import "../css/Posts.css";

const PostList = () => {
  const featuredPost = postlist[0];
  const relatedPosts = postlist.slice(1, 4);

  const excerpt = (content) => {
    return content.split(" ").slice(0, 20).join(" ") + "...";
  };

  return (
    <Layout>
      <div className="postlist">
        <div className="featured-post">
          <h2 className="featured-title">{featuredPost.title}</h2>
          {/* <img
            className="featured-thumbnail"
            src={featuredPost.thumbnail}
            alt={featuredPost.title}
          /> */}
          <small>
            Published on {featuredPost.date} by {featuredPost.author} <br />
          </small>
          <Markdown children={excerpt(featuredPost.content)} skipHtml={false} />
          <small>
            <Link className="links" to={`/blog/${featuredPost.id}`}>
              Read more
            </Link>
          </small>
        </div>
        <div className="related-posts">
          {relatedPosts.map((post, i) => (
            <div key={i} className="post-card">
              <div className="img-container">
                {post.thumbnail && (
                  <img
                    className="thumbnail"
                    src={post.thumbnail}
                    alt={post.title}
                  />
                )}
                <h3 className="post-title">
                  <Link className="links" to={`/blog/${post.id}`}>
                    {post.title}
                  </Link>
                </h3>
              </div>
              <small>
                Published on {post.date} by {post.author}
              </small>
              <Markdown children={excerpt(post.content)} skipHtml={false} />
              <small>
                <Link className="links" to={`/blog/${post.id}`}>
                  Read more
                </Link>
              </small>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default PostList;
