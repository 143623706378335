import React from 'react';
import { HiOutlineNewspaper } from 'react-icons/hi';
import "../../css/dashboard.css"
import image2 from "../../resources/section_third_img.png"
import cnn from "../../resources/cnn.png"
import vector from "../../resources/Vector.png"

const ThirdSection = () => {

    const mainContents = [
        { src: cnn, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "CNN" },
        { src: image2, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "NYT" },
        { src: image2, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "NYT" },
        { src: image2, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "CNN" },
        { src: image2, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "NYT" },
        { src: image2, alt: "cnn", text: "Biden’s tariffs on Chinese imports are boosting these beaten-down stocks", source: "NYT" },
      ];

      const lastSectionParts = [
        { color: "#2FEA9B", text: "70 % of your returns come from APPLE" },
        { color: "#FF7258", text: "80 % of your Portfolio risk come from Game Stop" },
      ];

  return (
    <div className="section_third">
      <div className="section_third_container">
        <div className="section_third_heading">
          <label>{"Insights by Rise AI"}</label>
          <HiOutlineNewspaper size={22} style={{ marginLeft: "20px" }} />
        </div>
        <div className="section_third_main">
          <div>
            {mainContents.slice(0, 3).map((content, index) => (
              <div key={index} className="main_content">
                <img className="section_third_image" src={content.src} alt={content.alt} />
                <div className="content">
                  <p>{content.text}</p>
                  <div className="content_section">
                    <p>{content.source}</p>
                    <img className="section_third_smallimage" src={vector} alt="vector" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            {mainContents.slice(3).map((content, index) => (
              <div key={index} className="main_content">
                <img className="section_third_image" src={content.src} alt={content.alt} />
                <div className="content">
                  <p>{content.text}</p>
                  <div className="content_section">
                    <p>{content.source}</p>
                    <img className="section_third_smallimage" src={vector} alt="vector" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="vert_line" />
          <div className="last_section">
            {lastSectionParts.map((part, index) => (
              <div key={index} className="last_section_part">
                <span className="round_shape" style={{ backgroundColor: part.color }}></span>
                <p>{part.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;