import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { UserAuthContextProvider } from "./context/AuthProvider";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <UserAuthContextProvider>
    <Router>
        <App />
    </Router>
  </UserAuthContextProvider>
);
