import React from "react";
import Markdown from "react-markdown";
import postlist from "../../posts.json";
import Layout from "./Layout";
import { useParams } from "react-router-dom";
import "../css/Posts.css";

const Post = (props) => {
  const match = useParams();
  const validId = match?.id;
  if (!validId) {
    // return <Redirect to="/404" />;
  }
  const fetchedPost = {};
  let postExists = false;
  postlist.forEach((post) => {
    if (validId === post.id) {
      fetchedPost.title = post.title ? post.title : "No title given";
      fetchedPost.date = post.date ? post.date : "No date given";
      fetchedPost.author = post.author ? post.author : "No author given";
      fetchedPost.content = post.content ? post.content : "No content given";
      fetchedPost.thumbnail = post.thumbnail ? post.thumbnail : null;
      fetchedPost.updatedDate = post.updatedDate ? post.updatedDate : null;
      fetchedPost.chapters = post.chapters ? post.chapters : [];
      fetchedPost.keyFindings = post.keyFindings ? post.keyFindings : [];
      postExists = true;
    }
  });
  if (!postExists) {
    // return <Redirect to="/404" />;
  }
  return (
    <Layout>
      <div className="post">
        <div className="post-header">
          <h1 className="post-title">{fetchedPost.title}</h1>
          {fetchedPost.thumbnail && (
            <img
              className="post-thumbnail"
              src={fetchedPost.thumbnail}
              alt={fetchedPost.title}
            />
          )}
          <div className="post-meta">
            <span className="post-author">{fetchedPost.author}</span>
            <span className="post-date">{fetchedPost.date}</span>
            {fetchedPost.updatedDate && (
              <span className="post-updated">
                Updated: {fetchedPost.updatedDate}
              </span>
            )}
          </div>
        </div>
        <Markdown className="post-content" skipHtml>
          {fetchedPost.content}
        </Markdown>
        {fetchedPost.chapters.length > 0 && (
          <div className="post-chapters">
            <h2>Chapters</h2>
            <div className="chapter-carousel">
              {fetchedPost.chapters.map((chapter, index) => (
                <div className="chapter-card" key={index}>
                  {chapter}
                </div>
              ))}
            </div>
          </div>
        )}
        {fetchedPost.keyFindings.length > 0 && (
          <div className="post-key-findings">
            <h2>Key Findings:</h2>
            <ul>
              {fetchedPost.keyFindings.map((finding, index) => (
                <li key={index}>{finding}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Post;
