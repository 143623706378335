import { useEffect, useState } from "react";
import { getAuth, onIdTokenChanged } from "firebase/auth";

const useFirebaseAuth = () => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setUser(user);
        setToken(token);
      } else {
        setUser(null);
        setToken(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return { user, token };
};

export default useFirebaseAuth;
