import React from "react";
import styled from "styled-components";
import { useChat } from "../context/ChatProvider";
import logo from "../logo.png";
import Login from "../components/Login";

const WrapperContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height : 100vh;
`;

const LoginPage = () => {
  // const { userName } = useChat();

  return (
    <WrapperContainer>
      <Login />
    </WrapperContainer>
  );
};

export default LoginPage;
