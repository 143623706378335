import React, { useContext, useMemo, useLayoutEffect } from "react";

import { SearchContext, SearchEventContext } from "./SearchProvider";

const MARK = "__$CTRL_F$__";

function escapeStr(str) {
  return `${str}`.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
}

function getMatchId(prefixId, index) {
  return `${prefixId}_${index}`;
}

function isValidColor(color) {
  const style = new Option().style;
  style.color = color;
  return (
    style.color !== "" &&
    color !== "unset" &&
    color !== "initial" &&
    color !== "inherit"
  );
}

function getMatchText(keyword, text, ignorecase = true) {
  let keywordStr = keyword;
  let textStr = text;
  if (typeof keyword === "number") {
    keywordStr = `${keyword}`;
  }
  if (typeof text === "number") {
    textStr = `${text}`;
  }
  if (
    typeof keywordStr !== "string" ||
    !keywordStr.trim() ||
    typeof textStr !== "string" ||
    !textStr.trim() ||
    !textStr.toLowerCase().includes(keywordStr.toLowerCase()) // case insensitive
  ) {
    return text;
  }
  const regexp = new RegExp(escapeStr(keywordStr), ignorecase ? "gi" : "g");
  const matches = []; // save matched string, we will use this to overwrite keywordStr in the result string
  const textWithMark = textStr.replace(regexp, (match) => {
    matches.push(match);
    return MARK;
  });
  const slices = textWithMark.split(MARK);
  const data = {
    slices,
    matches,
  };
  return data;
}

export const MatchText = (data) => {
  let textStr = data.children;
  let { searchValue, activeId, ignorecase } = useContext(SearchContext);
  const { onUpdateMatchList } = useContext(SearchEventContext);
  const matchData = useMemo(
    () => getMatchText(searchValue, textStr, ignorecase),
    [searchValue, textStr]
  );

  useLayoutEffect(() => {
    if (typeof matchData === "object" && matchData.matches) {
      const matchIds = matchData?.matches?.map((_, index) => ({
        id: getMatchId(id, index),
        idCount: index,
      }));
      onUpdateMatchList(matchIds);
    }
  }, [matchData]);
  const id = data.id;
  const activeColor = isValidColor(data.activeColor || "")
    ? data.activeColor
    : "#ff9632";
  const matchColor = isValidColor(data.matchColor || "")
    ? data.matchColor
    : "#ff9632";

  if (typeof data.children === "string") {
    textStr = data.children;
  }
  //   if (!textStr) {
  //     return <>{textStr}</>;
  //   }

  ignorecase =
    typeof data.ignorecase === "boolean" ? data.ignorecase : ignorecase;

  if (typeof matchData === "string") {
    return <div dangerouslySetInnerHTML={{ __html: matchData }}></div>;
  }
  const slicesLen = matchData?.slices?.length - 1;
  return (
    <React.Fragment>
      {matchData?.slices?.map((slice, index) => {
        if (index === slicesLen) {
          return slice;
        }
        const matchId = getMatchId(id, index);
        const color = matchId === activeId ? activeColor : matchColor;

        const matchStr = matchData.matches[index];
        return (
          <React.Fragment key={index}>
            {slice}
            <span
              id={matchId}
              style={{
                backgroundColor: color,
                display: "inline-block",
                whiteSpace: "pre-wrap",
              }}
            >
              {matchStr}
            </span>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default MatchText;
