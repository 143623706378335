import "./CubeSpinner.css";
import logo from "../../images/Frame.svg";

const Spincuber = () => {
  return (
    <div className="stage-cube-cont">
      <div className="cubespinner">
        <div className="face1">
          <img src={logo} width="150px" height="auto" alt="face 1"></img>
        </div>
        <div className="face2">
          <img src={logo} width="150px" height="auto" alt="face 2"></img>
        </div>
        <div className="face3">
          <img src={logo} width="150px" height="auto" alt="face 3"></img>
        </div>
        <div className="face4">
          <img src={logo} width="150px" height="auto" alt="face 4"></img>
        </div>
        <div className="face5">
          <img src={logo} width="150px" height="auto" alt="face 5"></img>
        </div>
        <div className="face6">
          <img src={logo} width="150px" height="auto" alt="face 6"></img>
        </div>
      </div>
    </div>
  );
};

export default Spincuber;
